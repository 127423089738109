// @font-face {
//   font-family: "font3336";
//   //src: url("../fonts/main-fonts.eot?v=20");
//   //src: url("../fonts/main-fonts.woff?v=20") format("woff"), url("../fonts/main-fonts.ttf?v=20") format("truetype"),
//   //  url("../fonts/main-fonts.svg?v=20") format("svg"), url("../fonts/main-fonts.eot?v=20") format("embedded-opentype");
//   font-weight: normal;
//   font-style: normal;
// }
.touch-disabled {
  .gjs-editor-cont {
    display: none;
  }
}
.gjs-is__grab,
.gjs-is__grab * {
  cursor: grab !important;
}
.gjs-is__grabbing,
.gjs-is__grabbing * {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: grabbing !important;
}
.gjs-one-bg {
  @apply tw-bg-transparent;
}
.gjs-one-color {
  @apply tw-text-gray-400;
}
.gjs-one-color-h:hover {
  @apply tw-text-gray-400;
}
.gjs-two-bg {
  @apply tw-bg-transparent;
}
.gjs-two-color {
  @apply tw-text-gray-400;
}
.gjs-two-color-h:hover {
  @apply tw-text-gray-400;
}
.gjs-three-bg {
  @apply tw-bg-primary-500;
}
.gjs-three-color {
  @apply tw-text-primary-500;
}
.gjs-three-color-h:hover {
  @apply tw-text-primary-500;
}
.gjs-four-bg {
  @apply tw-bg-secondary-500;
}
.gjs-four-color {
  @apply tw-text-secondary-500;
}
.gjs-four-color-h:hover {
  @apply tw-text-secondary-500;
}
.gjs-danger-bg {
  @apply tw-bg-error-500;
}
.gjs-danger-color {
  @apply tw-text-error-500;
}
.gjs-danger-color-h:hover {
  @apply tw-text-error-500;
}
.gjs-bg-main,
.gjs-sm-colorp-c,
.gjs-off-prv {
  @apply tw-bg-gray-400;
}
.gjs-color-main,
.gjs-sm-stack #gjs-sm-add,
.gjs-off-prv {
  color: #ddd;
  fill: #ddd;
}
.gjs-color-active {
  color: #f8f8f8;
  fill: #f8f8f8;
}
.gjs-color-warn {
  color: #ffca6f;
  fill: #ffca6f;
}
.gjs-color-hl {
  color: #71b7f1;
  fill: #71b7f1;
}
.gjs-fonts::before {
  display: block;
  font: normal normal normal 14px font3336;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 5em;
}
.gjs-f-b1::before {
  content: '';
}
.gjs-f-b2::before {
  content: '';
}
.gjs-f-b3::before {
  content: '';
}
.gjs-f-b37::before {
  content: '';
}
.gjs-f-hero::before {
  content: '';
}
.gjs-f-h1p::before {
  content: '';
}
.gjs-f-3ba::before {
  content: '';
}
.gjs-f-image::before {
  content: '';
}
.gjs-f-text::before {
  content: '';
}
.gjs-f-quo::before {
  content: '';
}
.gjs-f-button::before {
  content: '';
}
.gjs-f-divider::before {
  content: '';
}
.gjs-invis-invis,
.gjs-clm-tags #gjs-clm-new,
.gjs-no-app {
  background-color: transparent;
  border: none;
  color: inherit;
}
.gjs-no-app {
  height: 10px;
}
.gjs-test::btn {
  color: '#fff';
}
.opac50 {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.gjs-checker-bg,
.gjs-field-colorp-c,
.checker-bg,
.gjs-sm-layer-preview {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==');
}
.gjs-no-user-select,
.gjs-rte-toolbar,
.gjs-layer-name,
.gjs-grabbing,
.gjs-grabbing * {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.gjs-no-pointer-events,
.gjs-margin-v-el,
.gjs-padding-v-el,
.gjs-fixedmargin-v-el,
.gjs-fixedpadding-v-el,
.gjs-resizer-c {
  pointer-events: none;
}
.gjs-bdrag {
  pointer-events: none !important;
  position: absolute !important;
  z-index: 10 !important;
  width: auto;
}
.gjs-drag-helper {
  @apply tw-bg-primary-500 tw-pointer-events-none tw-absolute tw-z-10 tw-scale-50 tw-origin-top-left tw-m-4 tw-transition-none tw-outline-none;
}
.gjs-grabbing,
.gjs-grabbing * {
  cursor: grabbing !important;
  cursor: -webkit-grabbing !important;
}
.gjs-grabbing {
  overflow: hidden;
}
.gjs-off-prv {
  position: relative;
  z-index: 10;
  padding: 5px;
  cursor: pointer;
}
.gjs-editor-cont ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
.gjs-editor-cont ::-webkit-scrollbar-thumb {
  @apply tw-bg-neutral-50 tw-opacity-20;
}
.gjs-editor-cont ::-webkit-scrollbar {
  width: 8px;
}
.clear {
  clear: both;
}
.no-select,
.gjs-clm-tags #gjs-clm-close,
.gjs-category-title,
.gjs-layer-title,
.gjs-block-category .gjs-title,
.gjs-sm-sector-title,
.gjs-com-no-select,
.gjs-com-no-select img {
  @apply tw-select-none;
}
.gjs-no-touch-actions {
  touch-action: none;
}
.gjs-disabled {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.gjs-editor {
  @apply tw-relative tw-flex tw-flex-col tw-h-full tw-box-border tw-min-h-[50vh] tw-z-0;
}
.gjs-freezed,
.gjs-freezed {
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: none;
}
.gjs-traits-label {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  // font-weight: lighter;
  margin-bottom: 5px;
  padding: 10px;
  text-align: left;
}
.gjs-label-wrp {
  width: 30%;
  min-width: 30%;
}
.gjs-field-wrp {
  flex-grow: 1;
}
.gjs-trt-header {
  @apply tw-text-sm tw-text-gray-600 tw-p-2;
}
.gjs-trt-trait {
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px;
  // font-weight: lighter;
  align-items: center;
  text-align: left;
}
.gjs-trt-traits {
  font-size: 0.75rem;
}
.gjs-trt-trait .gjs-label {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}
.gjs-guide-info {
  position: absolute;
}
.gjs-guide-info__content {
  position: absolute;
  height: 100%;
  display: flex;
  width: 100%;
  padding: 5px;
}
.gjs-guide-info__line {
  position: relative;
  margin: auto;
}
.gjs-guide-info__line::before,
.gjs-guide-info__line::after {
  content: '';
  display: block;
  position: absolute;
  background-color: inherit;
}
.gjs-guide-info__y {
  padding: 0 5px;
}
.gjs-guide-info__y .gjs-guide-info__content {
  justify-content: center;
}
.gjs-guide-info__y .gjs-guide-info__line {
  width: 100%;
  height: 1px;
}
.gjs-guide-info__y .gjs-guide-info__line::before,
.gjs-guide-info__y .gjs-guide-info__line::after {
  width: 1px;
  height: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.gjs-guide-info__y .gjs-guide-info__line::after {
  left: auto;
  right: 0;
}
.gjs-guide-info__x {
  padding: 5px 0;
}
.gjs-guide-info__x .gjs-guide-info__content {
  align-items: center;
}
.gjs-guide-info__x .gjs-guide-info__line {
  height: 100%;
  width: 1px;
}
.gjs-guide-info__x .gjs-guide-info__line::before,
.gjs-guide-info__x .gjs-guide-info__line::after {
  width: 10px;
  height: 1px;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  transform: translateX(-50%);
}
.gjs-guide-info__x .gjs-guide-info__line::after {
  top: auto;
  bottom: 0;
}
.gjs-badge {
  position: absolute;
  white-space: nowrap;
}
.gjs-badge__icon {
  vertical-align: middle;
  display: inline-block;
  width: 15px;
  height: 15px;
}
.gjs-badge__icon svg {
  fill: currentColor;
}
.gjs-badge__name {
  display: inline-block;
  vertical-align: middle;
}
.gjs-frame-wrapper {
  position: absolute;
  width: 100%;
  min-height: 50vh;
  left: 0;
  right: 0;
  margin: auto;
}
.gjs-frame-wrapper--anim {
  transition: width 0.35s ease, height 0.35s ease;
}
.gjs-frame-wrapper__top {
  transform: translateY(-100%) translateX(-50%);
  display: flex;
  padding: 5px 0;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 0;
}
.gjs-frame-wrapper__top-r {
  margin-left: auto;
}
.gjs-frame-wrapper__left {
  position: absolute;
  left: 0;
  transform: translateX(-100%) translateY(-50%);
  height: 100%;
  top: 50%;
}
.gjs-frame-wrapper__bottom {
  position: absolute;
  bottom: 0;
  transform: translateY(100%) translateX(-50%);
  width: 100%;
  left: 50%;
}
.gjs-frame-wrapper__right {
  position: absolute;
  right: 0;
  transform: translateX(100%) translateY(-50%);
  height: 100%;
  top: 50%;
}
.gjs-frame-wrapper__icon {
  width: 24px;
  cursor: pointer;
}
.gjs-frame-wrapper__icon > svg {
  fill: currentColor;
}
.gjs-padding-v-top,
.gjs-fixedpadding-v-top {
  width: 100%;
  top: 0;
  left: 0;
}
.gjs-padding-v-right,
.gjs-fixedpadding-v-right {
  right: 0;
}
.gjs-padding-v-bottom,
.gjs-fixedpadding-v-bottom {
  width: 100%;
  left: 0;
  bottom: 0;
}
.gjs-padding-v-left,
.gjs-fixedpadding-v-left {
  left: 0;
}
.gjs-cv-canvas {
  @apply tw-bg-transparent tw-box-border tw-w-full tw-h-full tw-absolute tw-left-0 tw-top-[40px] tw-overflow-hidden;
  // background-color: rgba(0, 0, 0, 0.15);
  // box-sizing: border-box;
  // width: 85%;
  // height: calc(100% - 40px);
  // bottom: 0;
  // overflow: hidden;
  // z-index: 1;
  // position: absolute;
  // left: 0;
  // top: 40px;
}
.gjs-cv-canvas.gjs-cui {
  width: 100%;
  height: 100%;
  top: 0;
}
.gjs-cv-canvas.gjs-is__grab .gjs-cv-canvas__frames,
.gjs-cv-canvas.gjs-is__grabbing .gjs-cv-canvas__frames {
  pointer-events: none;
}
.gjs-cv-canvas__frames {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  min-height: 100vh;
}
.gjs-cv-canvas .gjs-ghost {
  display: none;
  pointer-events: none;
  background-color: #5b5b5b;
  border: 2px dashed #ccc;
  position: absolute;
  z-index: 10;
  opacity: 0.55;
  filter: alpha(opacity=55);
}
.gjs-cv-canvas .gjs-highlighter,
.gjs-cv-canvas .gjs-highlighter-sel {
  @apply tw-absolute tw-outline-1 tw-outline tw-outline-primary-500 tw-outline-offset-[-1px] tw-pointer-events-none tw-w-full tw-h-full;
}
.gjs-cv-canvas .gjs-highlighter-warning {
  outline: 3px solid #ffca6f;
}
.gjs-cv-canvas .gjs-highlighter-sel {
  @apply tw-outline-2 tw-outline tw-outline-primary-500 tw-outline-offset-[-2px];
}
.gjs-cv-canvas #gjs-tools,
.gjs-cv-canvas .gjs-tools {
  @apply tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-outline-none tw-z-10;
}
.gjs-cv-canvas * {
  @apply tw-box-border;
}

.gjs-cv-canvas__tools {
  @apply tw-box-border tw-z-auto;
}
.gjs-frame {
  @apply tw-inset-0 tw-min-h-[50vh];
  outline: medium none;
  height: 100%;
  width: 100%;
  border: none;
  margin: auto;
  display: block;
  transition: width 0.35s ease, height 0.35s ease;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
}
.gjs-toolbar {
  @apply tw-absolute tw-bg-primary-500 tw-whitespace-nowrap tw-text-neutral-50 tw-z-10 tw-top-0 tw-left-0;
}
.gjs-toolbar-item {
  @apply tw-w-7 tw-p-1 tw-cursor-pointer tw-inline-block;
  // width: 26px;
  // padding: 5px;
  // cursor: pointer;
  // display: inline-block;
}
.gjs-toolbar-item svg {
  fill: currentColor;
  vertical-align: middle;
}
.gjs-resizer-c {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.gjs-margin-v-el,
.gjs-padding-v-el,
.gjs-fixedmargin-v-el,
.gjs-fixedpadding-v-el {
  opacity: 0.1;
  filter: alpha(opacity=10);
  position: absolute;
  background-color: #ff0;
}
.gjs-fixedmargin-v-el,
.gjs-fixedpadding-v-el {
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.gjs-padding-v-el,
.gjs-fixedpadding-v-el {
  background-color: navy;
}
.gjs-resizer-h {
  pointer-events: all;
  position: absolute;
  border: 3px solid #3b97e3;
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin: -5px;
}
.gjs-resizer-h-tl {
  top: 0;
  left: 0;
  cursor: nwse-resize;
}
.gjs-resizer-h-tr {
  top: 0;
  right: 0;
  cursor: nesw-resize;
}
.gjs-resizer-h-tc {
  top: 0;
  margin: -5px auto;
  left: 0;
  right: 0;
  cursor: ns-resize;
}
.gjs-resizer-h-cl {
  left: 0;
  margin: auto -5px;
  top: 0;
  bottom: 0;
  cursor: ew-resize;
}
.gjs-resizer-h-cr {
  margin: auto -5px;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: ew-resize;
}
.gjs-resizer-h-bl {
  bottom: 0;
  left: 0;
  cursor: nesw-resize;
}
.gjs-resizer-h-bc {
  bottom: 0;
  margin: -5px auto;
  left: 0;
  right: 0;
  cursor: ns-resize;
}
.gjs-resizer-h-br {
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
}
.gjs-pn-panel .gjs-resizer-h {
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  opacity: 0;
  transition: opacity 0.25s;
}
.gjs-pn-panel .gjs-resizer-h:hover {
  opacity: 1;
}
.gjs-pn-panel .gjs-resizer-h-tc,
.gjs-pn-panel .gjs-resizer-h-bc {
  margin: 0 auto;
  width: 100%;
}
.gjs-pn-panel .gjs-resizer-h-cr,
.gjs-pn-panel .gjs-resizer-h-cl {
  margin: auto 0;
  height: 100%;
}
.gjs-resizing .gjs-highlighter,
.gjs-resizing .gjs-badge {
  display: none !important;
}
.gjs-resizing-tl * {
  cursor: nwse-resize !important;
}
.gjs-resizing-tr * {
  cursor: nesw-resize !important;
}
.gjs-resizing-tc * {
  cursor: ns-resize !important;
}
.gjs-resizing-cl * {
  cursor: ew-resize !important;
}
.gjs-resizing-cr * {
  cursor: ew-resize !important;
}
.gjs-resizing-bl * {
  cursor: nesw-resize !important;
}
.gjs-resizing-bc * {
  cursor: ns-resize !important;
}
.gjs-resizing-br * {
  cursor: nwse-resize !important;
}
.btn-cl,
.gjs-am-close,
.gjs-mdl-btn-close {
  @apply tw-text-3xl tw-cursor-pointer;
}
.btn-cl:hover,
.gjs-am-close:hover,
.gjs-mdl-btn-close:hover {
  // opacity: 0.7;
  // filter: alpha(opacity=70);
}
.no-dots,
.ui-resizable-handle {
  border: none !important;
  margin: 0 !important;
  outline: none !important;
}
.gjs-com-dashed * {
  outline: 1px dashed #888;
  outline-offset: -2px;
  box-sizing: border-box;
}
.gjs-cv-canvas .gjs-comp-selected {
  @apply tw-outline tw-outline-2 tw-outline-primary-500;
  // outline: 3px solid #3b97e3 !important;
}
*.gjs-com-hover,
div.gjs-com-hover {
  @apply tw-outline tw-outline-1 tw-outline-primary-500;
  // outline: 1px solid #3b97e3;
}
*.gjs-com-hover-delete,
div.gjs-com-hover-delete {
  outline: 2px solid #dd3636;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
*.gjs-com-hover-move,
div.gjs-com-hover-move {
  outline: 3px solid #ffca6f;
}
.gjs-com-badge,
.gjs-com-badge-red,
.gjs-badge {
  @apply tw-pointer-events-none tw-w-fit tw-text-neutral-50 tw-bg-primary-500 tw-px-2 tw-py-1 tw-z-10 tw-text-xs tw-outline-none tw-hidden;
}
.gjs-com-badge-red {
  background-color: #dd3636;
}
.gjs-badge-warning {
  background-color: #ffca6f;
}
.gjs-placeholder,
.gjs-com-placeholder,
.gjs-placeholder {
  position: absolute;
  z-index: 10;
  pointer-events: none;
  display: none;
}
.gjs-placeholder,
.gjs-placeholder {
  border-style: solid !important;
  outline: none;
  box-sizing: border-box;
  transition: top 0.2s, left 0.2s, width 0.2s, height 0.2s;
}
.gjs-placeholder.horizontal,
.gjs-com-placeholder.horizontal,
.gjs-placeholder.horizontal {
  border-color: transparent #62c462;
  border-width: 3px 5px;
  margin: -3px 0 0;
}
.gjs-placeholder.vertical,
.gjs-com-placeholder.vertical,
.gjs-placeholder.vertical {
  border-color: #62c462 transparent;
  border-width: 5px 3px;
  margin: 0 0 0 -3px;
}
.gjs-placeholder-int,
.gjs-com-placeholder-int,
.gjs-placeholder-int {
  background-color: #62c462;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  pointer-events: none;
  padding: 1.5px;
  outline: none;
}
.gjs-pn-panels {
  @apply tw-flex tw-order-1;
}
.gjs-pn-panel {
  @apply tw-inline-block tw-relative  tw-box-border tw-text-center tw-p-2 tw-z-30;
}
.gjs-pn-panel .icon-undo,
.gjs-pn-panel .icon-redo {
  font-size: 20px;
  height: 30px;
  width: 25px;
}
.gjs-pn-commands {
  // display: none;
  @apply tw-order-3 tw-max-h-5;
}
.gjs-pn-options {
  @apply tw-order-2 tw-max-h-5;
}
.gjs-pn-views {
  display: none;
  // @apply tw-order-4;
  // // border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  // right: 0;
  // width: 25%;
  // z-index: 4;
}
.gjs-pn-views-container {
  display: none;
  // // @apply hide;
  // @apply tw-order-5;
  // margin-left: -25%;
  // height: 100%;
  // padding: 42px 0 0;
  // right: 0;
  // width: 25%;
  // // overflow: auto;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.gjs-pn-buttons {
  @apply tw-items-center tw-flex tw-content-between;
}
.gjs-pn-btn {
  @apply tw-text-primary-700 tw-box-border tw-bg-transparent tw-border-none tw-rounded-sm tw-mr-1 tw-p-2 tw-relative;
}
.gjs-pn-btn.gjs-pn-active {
  @apply tw-shadow-inner;
}
.gjs-pn-btn svg {
  @apply tw-fill-white;
}
.gjs-comp-image-placeholder {
  display: block;
  background-color: #f5f5f5;
  color: #777;
  height: 50px;
  width: 50px;
  line-height: 50px;
  outline: 3px solid #ffca6f;
  outline-offset: -3px;
  text-align: center;
  font-size: 16.6666666667px;
  cursor: pointer;
}
.gjs-comp-image-placeholder.fa-picture-o::after {
  content: '';
}
.gjs-label {
  line-height: 18px;
}
.gjs-fields {
  display: flex;
}
.gjs-select {
  padding: 0;
  width: 100%;
}
.gjs-select select {
  padding-right: 10px;
}
.gjs-select:-moz-focusring,
.gjs-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
}
.gjs-input:focus,
.gjs-button:focus,
.gjs-btn-prim:focus,
.gjs-select:focus,
.gjs-select select:focus {
  outline: none;
}

.gjs-field input,
.gjs-field select,
.gjs-field textarea {
  @apply tw-appearance-none tw-block tw-w-full tw-mr-5 tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm;

  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  // color: inherit;
  // border: none;
  // background-color: transparent;
  // box-sizing: border-box;
  // width: 100%;
  // position: relative;
  // padding: 5px;
  // z-index: 1;
}
.gjs-field input:focus,
.gjs-field select:focus,
.gjs-field textarea:focus {
  outline: none;
}
.gjs-field input[type='number'] {
  -moz-appearance: textfield;
}
.gjs-field input[type='number']::-webkit-outer-spin-button,
.gjs-field input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.gjs-field-range {
  flex: 9 1 auto;
}
.gjs-field-integer input {
  padding-right: 30px;
}
.gjs-select option,
.gjs-field-select option,
.gjs-clm-select option,
.gjs-sm-select option,
.gjs-fields option,
.gjs-sm-unit option {
  background-color: #444;
  color: #ddd;
}
.gjs-field {
  @apply tw-bg-transparent tw-box-border tw-p-0 tw-relative tw-flex;
  // background-color: rgba(0, 0, 0, 0.2);
  // border: none;
  // box-shadow: none;
  // border-radius: 2px;
  // box-sizing: border-box;
  // padding: 0;
  // position: relative;
}
.gjs-field textarea {
  resize: vertical;
}
.gjs-field .gjs-sel-arrow {
  height: 100%;
  width: 9px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.gjs-field .gjs-d-s-arrow {
  bottom: 0;
  top: 0;
  margin: auto;
  right: 5px;
  border-top: 4px solid rgba(255, 255, 255, 0.7);
  position: absolute;
  height: 0;
  width: 0;
  border-left: 3px solid transparent;
  border-right: 4px solid transparent;
  cursor: pointer;
}
.gjs-field-arrows {
  position: absolute;
  cursor: ns-resize;
  margin: auto;
  height: 20px;
  width: 9px;
  z-index: 10;
  bottom: 0;
  right: 3px;
  top: 0;
}
.gjs-field-color,
.gjs-field-radio {
  width: 100%;
}
.gjs-field-color input {
  padding-right: 22px;
  box-sizing: border-box;
}
.gjs-field-colorp {
  @apply tw-h-full tw-w-5 tw-p-1 tw-rounded-md tw-absolute tw-right-0 tw-top-0 tw-border-neutral-400 tw-border-[1px];
  // border-left: 1px solid rgba(0, 0, 0, 0.2);
  // box-sizing: border-box;
  // height: 100%;
  // padding: 2px;
  // position: absolute;
  // right: 0;
  // top: 0;
  // width: 22px;
  // z-index: 10;
}
.gjs-field-colorp .gjs-checker-bg,
.gjs-field-colorp .gjs-field-colorp-c {
  @apply tw-w-full tw-h-full;
  // height: 100%;
  // width: 100%;
  // border-radius: 1px;
}
.gjs-field-colorp-c {
  height: 100%;
  position: relative;
  width: 100%;
}
.gjs-field-color-picker {
  background-color: #ddd;
  cursor: pointer;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  position: absolute;
  top: 0;
}
.gjs-field-checkbox {
  padding: 4px;
  width: 17px;
  height: 17px;
  display: block;
  cursor: pointer;
}
.gjs-field-checkbox input {
  display: block;
  color: var(--primary-500);
  width: 15px;
  height: 15px;
}
.gjs-field-checkbox input:checked + .gjs-chk-icon {
  display: none;
  // border-color: rgba(255, 255, 255, 0.5);
  // border-width: 0 2px 2px 0;
  // border-style: solid;
}
.gjs-radio-item {
  flex: 1 1 auto;
  text-align: center;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.gjs-radio-item:first-child {
  border: none;
}
.gjs-radio-item:hover {
  background: rgba(0, 0, 0, 0.2);
}
.gjs-radio-item input {
  display: none;
}
.gjs-radio-item input:checked + .gjs-radio-item-label {
  @apply tw-bg-primary-500;
  // background-color: rgba(255, 255, 255, 0.2);
}
.gjs-radio-items {
  @apply tw-isolate tw-inline-flex tw-rounded-md tw-shadow-sm tw-border-[1px] tw-border-neutral-400;
}
.gjs-radio-item-label {
  @apply tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 tw-block;
  // cursor: pointer;
  // display: block;
  // padding: 5px;
}
.gjs-field-units {
  @apply tw-inline tw-ml-1;
}
.gjs-field-unit {
  @apply tw-inline;
}
.gjs-input-unit {
  @apply tw-text-left;
  // text-align: center;
}
.gjs-field-arrow-u,
.gjs-field-arrow-d {
  position: absolute;
  height: 0;
  width: 0;
  border-left: 3px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.7);
  bottom: 4px;
  cursor: pointer;
}
.gjs-field-arrow-u {
  border-bottom: 4px solid rgba(255, 255, 255, 0.7);
  border-top: none;
  top: 4px;
}
.gjs-field-select {
  padding: 0;
}
.gjs-field-range {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}
.gjs-field-range input {
  margin: 0;
  height: 100%;
}
.gjs-field-range input:focus {
  outline: none;
}
.gjs-field-range input::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -4px;
  height: 10px;
  width: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background-color: #ddd;
  cursor: pointer;
}
.gjs-field-range input::-moz-range-thumb {
  height: 10px;
  width: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background-color: #ddd;
  cursor: pointer;
}
.gjs-field-range input::-ms-thumb {
  height: 10px;
  width: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background-color: #ddd;
  cursor: pointer;
}
.gjs-field-range input::-moz-range-track {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  margin-top: 3px;
  height: 3px;
}
.gjs-field-range input::-webkit-slider-runnable-track {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  margin-top: 3px;
  height: 3px;
}
.gjs-field-range input::-ms-track {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  margin-top: 3px;
  height: 3px;
}
.gjs-btn-prim {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  padding: 3px 6px;
  padding: 5px;
  cursor: pointer;
  border: none;
}
.gjs-btn-prim:active {
  background-color: rgba(255, 255, 255, 0.1);
}
.gjs-btn--full {
  width: 100%;
}
.gjs-chk-icon {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: border-box;
  display: block;
  height: 14px;
  margin: 0 5px;
  width: 6px;
}
.gjs-add-trasp {
  background: none;
  border: none;
  color: #ddd;
  cursor: pointer;
  font-size: 1em;
  border-radius: 2px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.gjs-add-trasp:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.gjs-add-trasp:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.gjs-pn-devices {
  @apply tw-order-1 tw-flex-grow tw-max-h-5;
}
.gjs-devices-c {
  @apply tw-flex tw-items-center tw-p-2;
}
.gjs-devices-c .gjs-device-label {
  flex-grow: 2;
  text-align: left;
  margin-right: 10px;
}
.gjs-devices-c .gjs-select {
  flex-grow: 20;
}
.gjs-devices-c .gjs-add-trasp {
  flex-grow: 1;
  margin-left: 5px;
}
.gjs-category-open,
.gjs-block-category.gjs-open,
.gjs-sm-sector.gjs-sm-open {
  @apply tw-transition;
}

.gjs-category,
.gjs-block-category,
.gjs-sm-sector {
  @apply tw-transition;
}

.gjs-category-title,
.gjs-layer-title,
.gjs-block-category .gjs-title,
.gjs-sm-sector-title {
  @apply tw-relative tw-flex tw-items-center tw-w-full tw-py-4 tw-px-1  tw-text-gray-600 tw-text-left tw-bg-white tw-border-0 tw-rounded-none tw-transition focus:tw-outline-none;
}

.gjs-category-title:first-of-type,
.gjs-layer-title:first-of-type,
.gjs-block-category:first-of-type,
.gjs-block-category:first-of-type .gjs-title,
.gjs-sm-sector:first-of-type,
.gjs-sm-sector:first-of-type .gjs-sm-sector-title {
  // @apply rounded-t-md;
}

.gjs-category-title:last-of-type,
.gjs-layer-title:last-of-type,
.gjs-block-category:last-of-type,
.gjs-block-category:last-of-type .gjs-title,
.gjs-sm-sector:last-of-type,
.gjs-sm-sector:last-of-type .gjs-sm-sector-title {
  // @apply rounded-b-md;
}

.gjs-category-title::after
// .gjs-layer-title::after,
//.gjs-sm-sector-title::after
// .gjs-block-category 
// .gjs-title::after
{
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  display: inline-block;
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22 fill=%22%232563eb%22%3E%3Cpath fill-rule=%22evenodd%22 d=%22M1.646 4.646a.5.5.0 01.708.0L8 10.293l5.646-5.647a.5.5.0 01.708.708l-6 6a.5.5.0 01-.708.0l-6-6a.5.5.0 010-.708z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.gjs-block-category {
  &.gjs-open {
    .gjs-title::after {
      background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22 fill=%22%232563eb%22%3E%3Cpath fill-rule=%22evenodd%22 d=%22M1.646 4.646a.5.5.0 01.708.0L8 10.293l5.646-5.647a.5.5.0 01.708.708l-6 6a.5.5.0 01-.708.0l-6-6a.5.5.0 010-.708z%22/%3E%3C/svg%3E');
      transform: rotate(-180deg);
    }
  }
}

.gjs-sm-sector {
  &.gjs-sm-open {
    .gjs-sector-title::after {
      background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22 fill=%22%232563eb%22%3E%3Cpath fill-rule=%22evenodd%22 d=%22M1.646 4.646a.5.5.0 01.708.0L8 10.293l5.646-5.647a.5.5.0 01.708.708l-6 6a.5.5.0 01-.708.0l-6-6a.5.5.0 010-.708z%22/%3E%3C/svg%3E');
      transform: rotate(-180deg);
    }
  }
}

.gjs-sm-clear {
  cursor: pointer;
  width: 14px;
  min-width: 14px;
  height: 14px;
  margin-left: 3px;
}
.gjs-sm-header {
  @apply tw-text-sm tw-text-gray-600 tw-p-2;
}
.gjs-sm-sector {
  clear: both;
  // font-weight: lighter;
  text-align: left;
}
.gjs-sm-sector-title {
  @apply tw-flex tw-items-center;
}
.gjs-sm-sector-caret {
  @apply tw-hidden;
}
.gjs-sm-sector-label {
  margin-left: 5px;
}
.gjs-sm-sector.gjs-sm-open .gjs-sm-sector-caret {
  @apply tw-hidden;
}
.gjs-sm-properties {
  font-size: 0.75rem;
  padding: 10px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  box-sizing: border-box;
  width: 100%;
}
.gjs-sm-label {
  @apply tw-block tw-text-sm tw-font-medium tw-text-gray-500;
}
.gjs-sm-close-btn,
.gjs-sm-preview-file-close {
  display: block;
  font-size: 23px;
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 0;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.gjs-sm-close-btn:hover,
.gjs-sm-preview-file-close:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.gjs-sm-field,
.gjs-clm-select,
.gjs-clm-field {
  width: 100%;
  position: relative;
}
.gjs-sm-field input,
.gjs-clm-select input,
.gjs-clm-field input,
.gjs-sm-field select,
.gjs-clm-select select,
.gjs-clm-field select {
  @apply tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm;
}
.gjs-sm-field input,
.gjs-clm-select input,
.gjs-clm-field input {
  box-sizing: border-box;
}
.gjs-sm-field select,
.gjs-clm-select select,
.gjs-clm-field select {
  position: relative;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.gjs-sm-field select::-ms-expand,
.gjs-clm-select select::-ms-expand,
.gjs-clm-field select::-ms-expand {
  display: none;
}
.gjs-sm-field select:-moz-focusring,
.gjs-clm-select select:-moz-focusring,
.gjs-clm-field select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
}
.gjs-sm-field input:focus,
.gjs-clm-select input:focus,
.gjs-clm-field input:focus,
.gjs-sm-field select:focus,
.gjs-clm-select select:focus,
.gjs-clm-field select:focus {
  outline: none;
}
.gjs-sm-field .gjs-sm-unit,
.gjs-clm-select .gjs-sm-unit,
.gjs-clm-field .gjs-sm-unit {
  position: absolute;
  right: 10px;
  top: 3px;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.gjs-sm-field .gjs-clm-sel-arrow,
.gjs-clm-select .gjs-clm-sel-arrow,
.gjs-clm-field .gjs-clm-sel-arrow,
.gjs-sm-field .gjs-sm-int-arrows,
.gjs-clm-select .gjs-sm-int-arrows,
.gjs-clm-field .gjs-sm-int-arrows,
.gjs-sm-field .gjs-sm-sel-arrow,
.gjs-clm-select .gjs-sm-sel-arrow,
.gjs-clm-field .gjs-sm-sel-arrow {
  height: 100%;
  width: 9px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: ns-resize;
}
.gjs-sm-field .gjs-sm-sel-arrow,
.gjs-clm-select .gjs-sm-sel-arrow,
.gjs-clm-field .gjs-sm-sel-arrow {
  cursor: pointer;
}
.gjs-sm-field .gjs-clm-d-s-arrow,
.gjs-clm-select .gjs-clm-d-s-arrow,
.gjs-clm-field .gjs-clm-d-s-arrow,
.gjs-sm-field .gjs-sm-d-arrow,
.gjs-clm-select .gjs-sm-d-arrow,
.gjs-clm-field .gjs-sm-d-arrow,
.gjs-sm-field .gjs-sm-d-s-arrow,
.gjs-clm-select .gjs-sm-d-s-arrow,
.gjs-clm-field .gjs-sm-d-s-arrow,
.gjs-sm-field .gjs-sm-u-arrow,
.gjs-clm-select .gjs-sm-u-arrow,
.gjs-clm-field .gjs-sm-u-arrow {
  position: absolute;
  height: 0;
  width: 0;
  border-left: 3px solid transparent;
  border-right: 4px solid transparent;
  cursor: pointer;
}
.gjs-sm-field .gjs-sm-u-arrow,
.gjs-clm-select .gjs-sm-u-arrow,
.gjs-clm-field .gjs-sm-u-arrow {
  border-bottom: 4px solid rgba(255, 255, 255, 0.7);
  top: 4px;
}
.gjs-sm-field .gjs-clm-d-s-arrow,
.gjs-clm-select .gjs-clm-d-s-arrow,
.gjs-clm-field .gjs-clm-d-s-arrow,
.gjs-sm-field .gjs-sm-d-arrow,
.gjs-clm-select .gjs-sm-d-arrow,
.gjs-clm-field .gjs-sm-d-arrow,
.gjs-sm-field .gjs-sm-d-s-arrow,
.gjs-clm-select .gjs-sm-d-s-arrow,
.gjs-clm-field .gjs-sm-d-s-arrow {
  border-top: 4px solid rgba(255, 255, 255, 0.7);
  bottom: 4px;
}
.gjs-sm-field .gjs-clm-d-s-arrow,
.gjs-clm-select .gjs-clm-d-s-arrow,
.gjs-clm-field .gjs-clm-d-s-arrow,
.gjs-sm-field .gjs-sm-d-s-arrow,
.gjs-clm-select .gjs-sm-d-s-arrow,
.gjs-clm-field .gjs-sm-d-s-arrow {
  bottom: 7px;
}
.gjs-sm-field.gjs-sm-color,
.gjs-sm-color.gjs-clm-field,
.gjs-sm-field.gjs-sm-input,
.gjs-sm-input.gjs-clm-field,
.gjs-sm-field.gjs-sm-integer,
.gjs-sm-integer.gjs-clm-field,
.gjs-sm-field.gjs-sm-list,
.gjs-sm-list.gjs-clm-field,
.gjs-sm-field.gjs-sm-select,
.gjs-clm-select,
.gjs-sm-select.gjs-clm-field {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0 5px;
}
.gjs-sm-field.gjs-sm-composite,
.gjs-sm-composite.gjs-clm-select,
.gjs-sm-composite.gjs-clm-field {
  border-radius: 2px;
}
.gjs-sm-field.gjs-sm-select,
.gjs-clm-select,
.gjs-sm-select.gjs-clm-field {
  padding: 0;
}
.gjs-sm-field.gjs-sm-select select,
.gjs-clm-select select,
.gjs-sm-select.gjs-clm-field select {
  height: 20px;
}
.gjs-sm-field.gjs-sm-select option,
.gjs-clm-select option,
.gjs-sm-select.gjs-clm-field option {
  padding: 3px 0;
}
.gjs-sm-field.gjs-sm-composite,
.gjs-sm-composite.gjs-clm-select,
.gjs-sm-composite.gjs-clm-field {
  @apply tw-bg-transparent tw-border-neutral-200 tw-border-2 tw-rounded-md;
  // background-color: rgba(0, 0, 0, 0.1);
  // border: 1px solid rgba(0, 0, 0, 0.25);
}
.gjs-sm-field.gjs-sm-list,
.gjs-sm-list.gjs-clm-select,
.gjs-sm-list.gjs-clm-field {
  width: auto;
  padding: 0;
  overflow: hidden;
  float: left;
}
.gjs-sm-field.gjs-sm-list input,
.gjs-sm-list.gjs-clm-select input,
.gjs-sm-list.gjs-clm-field input {
  display: none;
}
.gjs-sm-field.gjs-sm-list label,
.gjs-sm-list.gjs-clm-select label,
.gjs-sm-list.gjs-clm-field label {
  cursor: pointer;
  padding: 5px;
  display: block;
}
.gjs-sm-field.gjs-sm-list .gjs-sm-radio:checked + label,
.gjs-sm-list.gjs-clm-select .gjs-sm-radio:checked + label,
.gjs-sm-list.gjs-clm-field .gjs-sm-radio:checked + label {
  background-color: rgba(255, 255, 255, 0.2);
}
.gjs-sm-field.gjs-sm-list .gjs-sm-icon,
.gjs-sm-list.gjs-clm-select .gjs-sm-icon,
.gjs-sm-list.gjs-clm-field .gjs-sm-icon {
  background-repeat: no-repeat;
  background-position: center;
  text-shadow: none;
  line-height: normal;
}
.gjs-sm-field.gjs-sm-integer select,
.gjs-sm-integer.gjs-clm-select select,
.gjs-sm-integer.gjs-clm-field select {
  width: auto;
  padding: 0;
}
.gjs-sm-list .gjs-sm-el {
  float: left;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.gjs-sm-list .gjs-sm-el:first-child {
  border: none;
}
.gjs-sm-list .gjs-sm-el:hover {
  background: rgba(0, 0, 0, 0.2);
}
.gjs-sm-slider .gjs-field-integer {
  flex: 1 1 65px;
}
.gjs-sm-property {
  box-sizing: border-box;
  float: left;
  width: 50%;
  margin-bottom: 5px;
  padding: 0 5px;
}
.gjs-sm-property--full,
.gjs-sm-property.gjs-sm-composite,
.gjs-sm-property.gjs-sm-file,
.gjs-sm-property.gjs-sm-list,
.gjs-sm-property.gjs-sm-stack,
.gjs-sm-property.gjs-sm-slider,
.gjs-sm-property.gjs-sm-color {
  width: 100%;
}
.gjs-sm-property .gjs-sm-btn {
  background-color: rgba(33, 33, 33, 0.2);
  border-radius: 2px;
  box-shadow: 1px 1px 0 rgba(5, 5, 5, 0.2),
    1px 1px 0 rgba(43, 43, 43, 0.2) inset;
  padding: 5px;
  position: relative;
  text-align: center;
  height: auto;
  width: 100%;
  cursor: pointer;
  color: #ddd;
  box-sizing: border-box;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.2);
  border: none;
  opacity: 0.85;
  filter: alpha(opacity=85);
}
.gjs-sm-property .gjs-sm-btn-c {
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.gjs-sm-property__text-shadow .gjs-sm-layer-preview-cnt::after {
  color: #000;
  content: 'T';
  font-weight: 900;
  line-height: 17px;
  padding: 0 4px;
}
.gjs-sm-preview-file {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  margin-top: 5px;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(252, 252, 252, 0.05);
  padding: 3px 20px;
}
.gjs-sm-preview-file-cnt {
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  height: 50px;
}
.gjs-sm-preview-file-close {
  top: -5px;
  width: 14px;
  height: 14px;
}
.gjs-sm-layers {
  margin-top: 5px;
  padding: 1px 3px;
  min-height: 30px;
}
.gjs-sm-layer {
  background-color: rgba(255, 255, 255, 0.055);
  border-radius: 2px;
  margin: 2px 0;
  padding: 7px;
  position: relative;
}
.gjs-sm-layer.gjs-sm-active {
  background-color: rgba(255, 255, 255, 0.12);
}
.gjs-sm-layer .gjs-sm-label-wrp {
  display: flex;
  align-items: center;
}
.gjs-sm-layer #gjs-sm-move {
  height: 14px;
  width: 14px;
  min-width: 14px;
  cursor: grab;
}
.gjs-sm-layer #gjs-sm-label {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 5px;
}
.gjs-sm-layer-preview {
  height: 15px;
  width: 15px;
  min-width: 15px;
  margin-right: 5px;
  border-radius: 2px;
}
.gjs-sm-layer-preview-cnt {
  border-radius: 2px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  background-size: cover !important;
}
.gjs-sm-layer #gjs-sm-close-layer {
  display: block;
  cursor: pointer;
  height: 14px;
  width: 14px;
  min-width: 14px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.gjs-sm-layer #gjs-sm-close-layer:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.gjs-sm-stack .gjs-sm-properties {
  padding: 5px 0 0;
}
.gjs-sm-stack #gjs-sm-add {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 0;
  top: -17px;
  opacity: 0.75;
  padding: 0;
  width: 18px;
  height: 18px;
}
.gjs-sm-stack #gjs-sm-add:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.gjs-sm-colorp-c {
  @apply tw-h-full tw-w-5 tw-p-1 tw-rounded-md;
  // height: 100%;
  // width: 20px;
  // position: absolute;
  // right: 0;
  // top: 0;
  // box-sizing: border-box;
  // border-radius: 2px;
  // padding: 2px;
}
.gjs-sm-colorp-c .gjs-checker-bg,
.gjs-sm-colorp-c .gjs-field-colorp-c {
  height: 100%;
  width: 100%;
  border-radius: 1px;
}
.gjs-sm-color-picker {
  background-color: #ddd;
  cursor: pointer;
  height: 16px;
  width: 100%;
  margin-top: -16px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
}
.gjs-sm-btn-upload #gjs-sm-upload {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.gjs-sm-btn-upload #gjs-sm-label {
  padding: 2px 0;
}
.gjs-sm-layer > #gjs-sm-move {
  opacity: 0.7;
  filter: alpha(opacity=70);
  cursor: move;
  font-size: 12px;
  float: left;
  margin: 0 5px 0 0;
}
.gjs-sm-layer > #gjs-sm-move:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.gjs-blocks-c {
  @apply tw-flex tw-flex-wrap tw-justify-start tw-py-4 tw-px-5;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: flex-start;
}
.gjs-block-categories,
.gjs-sm-sectors {
  @apply tw-flex tw-flex-col tw-rounded-md;
  // display: flex;
  // flex-direction: column;
}
.gjs-block-category,
.gjs-sm-sector {
  @apply tw-bg-white tw-border tw-border-gray-200;
  // width: 100%;
}
.gjs-block-category .gjs-caret-icon {
  @apply tw-hidden;
  // margin-right: 5px;
}
.gjs-block {
  @apply tw-w-[45%] tw-select-none tw-p-1 tw-m-1 tw-box-border tw-font-medium tw-text-base tw-text-center tw-flex tw-flex-col tw-justify-between tw-rounded-md tw-border-2 tw-border-neutral-200;
}
.gjs-block:hover {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
}
.gjs-block svg {
  fill: currentColor;
}
.gjs-block__media {
  margin-bottom: 10px;
  pointer-events: none;
}
.gjs-block-svg {
  width: 54px;
  fill: currentColor;
}
.gjs-block-svg-path {
  fill: currentColor;
}
.gjs-block.fa {
  font-size: 2em;
  line-height: 2em;
  padding: 11px;
}
.gjs-block-label {
  line-height: normal;
  font-size: 0.65rem;
  font-weight: normal;
  font-family: Helvetica, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}
.gjs-block.gjs-bdrag {
  width: auto;
  padding: 0;
}
.gjs-selected {
  @apply tw-outline tw-outline-2 tw-outline-primary-500;
}

.gjs-selected-parent {
  @apply tw-border-2 tw-border-secondary-500;
}

.gjs-opac50 {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.gjs-layer {
  @apply tw-relative tw-bg-transparent tw-text-gray-700 tw-text-sm tw-grid;
  // font-weight: lighter;
  // text-align: left;
  // position: relative;
  // background-color: rgba(0, 0, 0, 0.1);
  // font-size: 0.75rem;
  // display: grid;
}
.gjs-layer-hidden {
  opacity: 0.55;
  filter: alpha(opacity=55);
}
.gjs-layer-count {
  position: absolute;
  right: 27px;
  top: 11px;
}
.gjs-layer-vis {
  @apply tw-absolute tw-left-0 tw-top-2.5 tw-p-1 tw-w-6 tw-h-6 tw-z-10 tw-text-gray-500;
  // left: 0;
  // top: 0;
  // padding: 7px 5px 7px 10px;
  // position: absolute;
  // cursor: pointer;
  // width: 13px;
  // z-index: 1;
}
.gjs-layer-vis-off {
  display: none;
}
.gjs-layer-vis.gjs-layer-off .gjs-layer-vis-on {
  display: none;
}
.gjs-layer-vis.gjs-layer-off .gjs-layer-vis-off {
  display: block;
}
.gjs-layer-caret {
  @apply tw-w-7 tw-p-1 -tw-left-6 tw-top-1 tw-absolute tw-rotate-90 tw-opacity-70;
  // width: 15px;
  // padding: 2px;
  // cursor: pointer;
  // position: absolute;
  // left: -15px;
  // top: 0;
  // transform: rotate(90deg);
  // opacity: 0.7;
  // filter: alpha(opacity=70);
}
.gjs-layer-caret:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.gjs-layer-caret.gjs-layer-open {
  transform: rotate(180deg);
}
.gjs-layer-title {
  padding: 3px 10px 5px 30px;
  display: flex;
  align-items: center;
}
.gjs-layer-title-inn {
  @apply tw-flex tw-items-center tw-relative tw-w-full tw-pb-2;
  // align-items: center;
  // position: relative;
  // display: flex;
  // width: 100%;
}
.gjs-layer__icon {
  display: block;
  width: 100%;
  max-width: 15px;
  max-height: 15px;
  padding-left: 5px;
}
.gjs-layer__icon svg {
  fill: currentColor;
}
.gjs-layer-name {
  @apply tw-py-2 tw-inline-block tw-overflow-hidden tw-whitespace-nowrap tw-w-full tw-box-content;
  // padding: 5px 0;
  // display: inline-block;
  // box-sizing: content-box;
  // overflow: hidden;
  // white-space: nowrap;
  // margin: 0 30px 0 5px;
  // max-width: 170px;
}
.gjs-layer-name--no-edit {
  text-overflow: ellipsis;
}
.gjs-layer > .gjs-layer-children {
  display: none;
}
.gjs-layer.open > .gjs-layer-children {
  display: block;
}
.gjs-layer-no-chld > .gjs-layer-title-inn > .gjs-layer-caret {
  display: none;
}
.gjs-layer-move {
  @apply tw-absolute tw-cursor-move tw-right-0 tw-top-[8px] tw-p-1 tw-w-6 tw-h-6 tw-z-10 tw-text-gray-500;
  // padding: 9px 7px;
  // position: absolute;
  // width: 13px;
  // cursor: move;
  // right: 0;
  // top: 0;
}
// .gjs-layer.gjs-hovered {
//   .gjs-layer-title {
//     @apply tw-bg-primary-800 tw-text-primary-50;
//   }
//   .gjs-layer-count {
//     @apply tw-text-primary-200;
//   }
//   .gjs-layer-vis {
//     @apply tw-text-primary-50;
//   }
// }
.gjs-layer.gjs-selected .gjs-layer-title {
  @apply tw-bg-gray-200;
}
.gjs-layers {
  position: relative;
  height: 100%;
}
.gjs-layers #gjs-placeholder {
  width: 100%;
  position: absolute;
}
.gjs-layers #gjs-placeholder #gjs-plh-int {
  height: 100%;
  padding: 1px;
}
.gjs-layers #gjs-placeholder #gjs-plh-int.gjs-insert {
  background-color: #62c462;
}
#gjs-clm-add-tag,
.gjs-clm-tags-btn {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 2px;
  padding: 3px;
  margin-right: 3px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  cursor: pointer;
}
.gjs-clm-tags-btn svg {
  fill: currentColor;
  display: block;
}
.gjs-clm-header {
  display: flex;
  align-items: center;
  margin: 7px 0;
}
.gjs-clm-header-status {
  flex-shrink: 1;
  margin-left: auto;
}
.gjs-clm-tag {
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 3px;
  margin: 0 3px 3px 0;
  padding: 5px;
  cursor: default;
}
.gjs-clm-tag-status,
.gjs-clm-tag-close {
  width: 12px;
  height: 12px;
  flex-shrink: 1;
}
.gjs-clm-tag-status svg,
.gjs-clm-tag-close svg {
  vertical-align: middle;
  fill: currentColor;
}
.gjs-clm-sels-info {
  margin: 7px 0;
  text-align: left;
}
.gjs-clm-sel-id {
  font-size: 0.9em;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.gjs-clm-label-sel {
  float: left;
  padding-right: 5px;
}
.gjs-clm-tags {
  font-size: 0.75rem;
  padding: 10px 5px;
}
.gjs-clm-tags #gjs-clm-sel {
  padding: 7px 0;
  float: left;
}
.gjs-clm-tags #gjs-clm-sel {
  font-style: italic;
  margin-left: 5px;
}
.gjs-clm-tags #gjs-clm-tags-field {
  clear: both;
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}
.gjs-clm-tags #gjs-clm-tags-c {
  display: flex;
  flex-wrap: wrap;
  vertical-align: top;
  overflow: hidden;
}
.gjs-clm-tags #gjs-clm-new {
  color: #ddd;
  padding: 5px 6px;
  display: none;
}
.gjs-clm-tags #gjs-clm-close {
  opacity: 0.85;
  filter: alpha(opacity=85);
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
}
.gjs-clm-tags #gjs-clm-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.gjs-clm-tags #gjs-clm-checkbox {
  color: rgba(255, 255, 255, 0.9);
  vertical-align: middle;
  cursor: pointer;
  font-size: 9px;
}
.gjs-clm-tags #gjs-clm-tag-label {
  @apply tw-flex-grow tw-text-ellipsis tw-overflow-hidden tw-px-1 tw-cursor-text tw-text-gray-50;
  // flex-grow: 1;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // padding: 0 3px;
  // cursor: text;
}
.gjs-mdl-container {
  @apply tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity tw-z-50;
  // font-family: Helvetica, sans-serif;
  // overflow-y: auto;
  // position: fixed;
  // background-color: rgba(0, 0, 0, 0.5);
  // display: flex;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // z-index: 100;;
}
.gjs-mdl-dialog {
  @apply tw-fixed tw-flex tw-flex-col tw-mx-auto tw-inset-0 tw-z-10 tw-overflow-y-auto tw-transform  tw-rounded-lg tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-[75%] sm:tw-p-6;
}
.gjs-mdl-title {
  @apply tw-text-lg tw-flex-grow;
}
.gjs-mdl-btn-close {
  // position: absolute;
  // right: 15px;
  // top: 5px;
}
.gjs-mdl-active .gjs-mdl-dialog {
  animation: gjs-mdl-slide-down 0.216s;
}
.gjs-mdl-header,
.gjs-mdl-content {
  @apply tw-py-4 tw-px-5;
}
.gjs-mdl-header {
  @apply tw-flex tw-items-center;
}
.gjs-export-dl::after {
  content: '';
  clear: both;
  display: block;
  margin-bottom: 10px;
}
.gjs-dropzone {
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.25s;
  pointer-events: none;
}
.gjs-dropzone-active .gjs-dropzone {
  display: block;
  opacity: 1;
}
.gjs-am-assets {
  height: 290px;
  overflow: auto;
  clear: both;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
.gjs-am-assets-header {
  padding: 5px;
}
.gjs-am-add-asset .gjs-am-add-field {
  width: 70%;
  float: left;
}
.gjs-am-add-asset button {
  width: 25%;
  float: right;
}
.gjs-am-preview-cont {
  position: relative;
  height: 70px;
  width: 30%;
  background-color: #444;
  border-radius: 2px;
  float: left;
  overflow: hidden;
}
.gjs-am-preview {
  position: absolute;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.gjs-am-preview-bg {
  opacity: 0.5;
  filter: alpha(opacity=50);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.gjs-am-dimensions {
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 10px;
}
.gjs-am-meta {
  width: 70%;
  float: left;
  font-size: 12px;
  padding: 5px 0 0 5px;
  box-sizing: border-box;
}
.gjs-am-meta > div {
  margin-bottom: 5px;
}
.gjs-am-close {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0;
  display: none;
}
.gjs-am-asset {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.gjs-am-asset:hover .gjs-am-close {
  display: block;
}
.gjs-am-highlight {
  background-color: rgba(255, 255, 255, 0.1);
}
.gjs-am-assets-cont {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  width: 45%;
  float: right;
  height: 325px;
  overflow: hidden;
}
.gjs-am-file-uploader {
  width: 55%;
  float: left;
}
.gjs-am-file-uploader > form {
  background-color: rgba(0, 0, 0, 0.1);
  border: 2px dashed;
  border-radius: 3px;
  position: relative;
  text-align: center;
  margin-bottom: 15px;
}
.gjs-am-file-uploader > form.gjs-am-hover {
  border: 2px solid #62c462;
  color: #75cb75;
}
.gjs-am-file-uploader > form.gjs-am-disabled {
  border-color: red;
}
.gjs-am-file-uploader > form #gjs-am-uploadFile {
  opacity: 0;
  filter: alpha(opacity=0);
  padding: 150px 10px;
  width: 100%;
  box-sizing: border-box;
}
.gjs-am-file-uploader #gjs-am-title {
  position: absolute;
  padding: 150px 10px;
  width: 100%;
}
.gjs-cm-editor-c {
  float: left;
  box-sizing: border-box;
  width: 50%;
}
.gjs-cm-editor-c .CodeMirror {
  height: 450px;
}
.gjs-cm-editor {
  font-size: 12px;
}
.gjs-cm-editor#gjs-cm-htmlmixed {
  padding-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.gjs-cm-editor#gjs-cm-htmlmixed #gjs-cm-title {
  color: #a97d44;
}
.gjs-cm-editor#gjs-cm-css {
  padding-left: 10px;
}
.gjs-cm-editor#gjs-cm-css #gjs-cm-title {
  color: #ddca7e;
}
.gjs-cm-editor #gjs-cm-title {
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
  padding: 5px 10px 3px;
  text-align: right;
}
.gjs-rte-toolbar {
  @apply tw-border-[1px] tw-border-primary-50 tw-absolute tw-rounded-sm tw-z-10 tw-bg-primary-600 tw-text-neutral-100;
}
.gjs-rte-actionbar {
  display: flex;
}
.gjs-rte-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 25px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  cursor: pointer;
  outline: none;
}
.gjs-rte-action:last-child {
  border-right: none;
}
.gjs-rte-action:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.gjs-rte-active {
  background-color: rgba(255, 255, 255, 0.1);
}
.gjs-rte-disabled {
  color: rgba(255, 255, 255, 0.1);
  cursor: not-allowed;
}
.gjs-rte-disabled:hover {
  background-color: unset;
}
.gjs-editor-cont .sp-hue,
.gjs-editor-cont .sp-slider {
  cursor: row-resize;
}
.gjs-editor-cont .sp-color,
.gjs-editor-cont .sp-dragger {
  cursor: crosshair;
}
.gjs-editor-cont .sp-alpha-inner,
.gjs-editor-cont .sp-alpha-handle {
  cursor: col-resize;
}
.gjs-editor-cont .sp-hue {
  left: 90%;
}
.gjs-editor-cont .sp-color {
  right: 15%;
}
.gjs-editor-cont .sp-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.gjs-editor-cont .sp-picker-container {
  border: none;
}
.gjs-editor-cont .colpick_dark .colpick_color {
  outline: 1px solid rgba(0, 0, 0, 0.2);
}
.gjs-editor-cont .sp-cancel,
.gjs-editor-cont .sp-cancel:hover {
  @apply tw-flex-grow tw-text-neutral-500 tw-no-underline;
  // bottom: -8px;
  // color: #777 !important;
  // font-size: 25px;
  // left: 0;
  // position: absolute;
  // text-decoration: none;
}
.gjs-editor-cont .sp-alpha-handle {
  background-color: #ccc;
  border: 1px solid #555;
  width: 4px;
}
.gjs-editor-cont .sp-color,
.gjs-editor-cont .sp-hue {
  border: 1px solid #333;
}
.gjs-editor-cont .sp-slider {
  background-color: #ccc;
  border: 1px solid #555;
  height: 3px;
  left: -4px;
  width: 22px;
}
.gjs-editor-cont .sp-dragger {
  background: transparent;
  box-shadow: 0 0 0 1px #111;
}
.gjs-editor-cont .sp-button-container {
  @apply tw-flex tw-w-full tw-items-baseline;
  // float: none;
  // width: 100%;
  // position: relative;
  // text-align: right;
}
.gjs-editor-cont .sp-container button,
.gjs-editor-cont .sp-container button:hover,
.gjs-editor-cont .sp-container button:active {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-primary-600  tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-white tw-shadow-sm  focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2;
}
.gjs-editor-cont .sp-palette-container {
  border: none;
  float: none;
  margin: 0;
  padding: 5px 10px 0;
}
.gjs-editor-cont .sp-palette .sp-thumb-el,
.gjs-editor-cont .sp-palette .sp-thumb-el:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
}
.gjs-editor-cont .sp-palette .sp-thumb-el:hover,
.gjs-editor-cont .sp-palette .sp-thumb-el.sp-thumb-active {
  border-color: rgba(0, 0, 0, 0.9);
}
.gjs-hidden {
  display: none;
}
// @keyframes gjs-slide-down {
//   0% {
//     transform: translate(0, -3rem);
//     opacity: 0;
//   }
//   100% {
//     transform: translate(0, 0);
//     opacity: 1;
//   }
// }
// @keyframes gjs-slide-up {
//   0% {
//     transform: translate(0, 0);
//     opacity: 1;
//   }
//   100% {
//     transform: translate(0, -3rem);
//     opacity: 0;
//   }
// }
.cm-s-hopscotch span.cm-error {
  color: #fff;
}

/* Class names prefixes */
$prefix: 'gjs-' !default;

// .code-panel {
//   text-align: left;
//   font-size: 1rem;
//   height: 100%;
//   overflow: hidden;
// }

// .code-panel section {
//   flex: 1;
//   height: 50%;
// }

// .code-panel section .codepanel-separator {
//   display: flex;
//   justify-content: space-between;
//   padding-left: 0.6rem;
//   padding-right: 0.6rem;
// }

// .code-panel section .codepanel-label {
//   margin-top: 5px;
//   line-height: 20px;
//   font-size: 13px;
//   color: #aaa;
//   user-select: none;
//   text-transform: uppercase;
// }

// .cp-btn-container {
//   display: flex;
//   justify-content: space-evenly;

//   & .#{$prefix}btn-prim {
//     margin: 2.5px;
//   }
// }

// .gutter {
//   cursor: ns-resize;
//   position: relative;
//   background-color: rgba(0, 0, 0, 0.2);
// }

// .gutter:after {
//   content: "";
//   display: block;
//   height: 8px;
//   width: 100%;
//   position: absolute;
//   top: -3px;
//   z-index: 150;
// }

// .code-panel .CodeMirror {
//   height: 100%;
// }

// .cp-delete-css {
//   margin-left: 70%;
// }

.#{$prefix}pn-views {
  // border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0;
}

.#{$prefix}pn-views-container {
  @apply tw-border-t-[1px] tw-border-gray-200;
  box-shadow: initial;
  top: 40px;
  padding-top: 0;
  // height: calc(100% - 40px);
}

.#{$prefix}pn-views-container,
.#{$prefix}cv-canvas {
  transition: width 0.3s ease-in-out;
}

.typeahead-standalone {
  position: relative;
  width: 100%;
  color: #fff;
  text-align: left;
  z-index: 1000000;
}

.typeahead-standalone .tt-input {
  width: 100%;
  background: transparent;
  z-index: 1;
}

.typeahead-standalone .tt-hint {
  background: #363636;
  color: #fff;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  z-index: 0;
}

.typeahead-standalone .tt-list {
  background: #363636;
  border: 1px solid #aaa;
  box-sizing: border-box;
  overflow: auto;
  position: absolute;
  width: 98%;
  z-index: 1000;
}

.typeahead-standalone .tt-list.tt-hide {
  display: none;
}

.typeahead-standalone .tt-list div[class^='tt-'] {
  padding: 5px;
}

.typeahead-standalone .tt-list .tt-suggestion.tt-selected,
.typeahead-standalone .tt-list .tt-suggestion:hover {
  background: #804f7b;
  cursor: pointer;
}

.typeahead-standalone .tt-list .tt-suggestion .tt-highlight {
  font-weight: 900;
  color: #3b97e3;
}

.typeahead-standalone .tt-list .tt-group {
  background: #363636;
}
.typeahead-standalone .preview {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 10px;
  border: 1px solid #313131;
}
.typeahead-standalone .property {
  position: absolute;
  min-width: 20px;
  max-width: 150px;
  text-overflow: ellipsis;
  right: 10px;
  color: #aaa;
}
.gjs-clm-tag-status {
  display: none;
}

.tw-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9IjAgMCA1MSAzMSIgc3R5bGU9IndpZHRoOmF1dG87aGVpZ2h0OjEuMjVyZW0iPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjUuNTE3IDBDMTguNzEyIDAgMTQuNDYgMy4zODIgMTIuNzU4IDEwLjE0NmMyLjU1Mi0zLjM4MiA1LjUyOS00LjY1IDguOTMxLTMuODA1IDEuOTQxLjQ4MiAzLjMyOSAxLjg4MiA0Ljg2NCAzLjQzMiAyLjUwMiAyLjUyNCA1LjM5OCA1LjQ0NSAxMS43MjIgNS40NDUgNi44MDQgMCAxMS4wNTctMy4zODIgMTIuNzU4LTEwLjE0NS0yLjU1MSAzLjM4Mi01LjUyOCA0LjY1LTguOTMgMy44MDQtMS45NDItLjQ4Mi0zLjMzLTEuODgyLTQuODY1LTMuNDMxQzM0LjczNiAyLjkyIDMxLjg0MSAwIDI1LjUxNyAwek0xMi43NTggMTUuMjE4QzUuOTU0IDE1LjIxOCAxLjcwMSAxOC42IDAgMjUuMzY0YzIuNTUyLTMuMzgyIDUuNTI5LTQuNjUgOC45My0zLjgwNSAxLjk0Mi40ODIgMy4zMyAxLjg4MiA0Ljg2NSAzLjQzMiAyLjUwMiAyLjUyNCA1LjM5NyA1LjQ0NSAxMS43MjIgNS40NDUgNi44MDQgMCAxMS4wNTctMy4zODEgMTIuNzU4LTEwLjE0NS0yLjU1MiAzLjM4Mi01LjUyOSA0LjY1LTguOTMxIDMuODA1LTEuOTQxLS40ODMtMy4zMjktMS44ODMtNC44NjQtMy40MzItMi41MDItMi41MjQtNS4zOTgtNS40NDYtMTEuNzIyLTUuNDQ2eiIgZmlsbD0iIzM4YmRmOCI+PC9wYXRoPjwvc3ZnPg==');
}

.gjs-tailwind-error {
  color: lightblue;
  font-size: 0.75rem;
  font-weight: lighter;
  padding: 5px;
  height: 20px;
}

/* Style the tab */
.tab {
  overflow: hidden;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: rgba(255, 255, 255, 0.1);
  color: #d5d3d5;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 10px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #322931;
}

/* Style the tab content */
.tabcontent.active {
  display: block;
}

.tabcontent:not(.active) {
  display: none;
}

.panel-toggle {
  &.open {
    svg.open {
      display: none;
    }
    svg.close {
      display: block;
    }
  }
  &.close {
    svg.close {
      display: none;
    }
    svg.open {
      display: block;
    }
  }
}

.slideover-open {
  // @apply tw-translate-x-0;
  #layers-floater,
  #code-floater,
  #traits-floater,
  #styles-floater {
    @apply tw-opacity-100 tw-pointer-events-auto;
  }
}

.slideover-closed {
  // @apply tw-translate-x-full;
  #layers-floater,
  #code-floater,
  #traits-floater,
  #styles-floater {
    @apply tw-opacity-0 tw-pointer-events-none;
  }
}

.code-editor-open {
  @apply tw-min-h-[50vh] tw-h-[50vh];
}

.code-panel {
  text-align: left;
  font-size: 1rem;
  height: 100%;
  overflow: hidden;
}

.code-panel section {
  flex: 1;
  height: 50%;
}

.code-panel section .codepanel-separator {
  display: flex;
  justify-content: space-between;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.code-panel section .codepanel-label {
  margin-top: 5px;
  line-height: 20px;
  font-size: 13px;
  color: #aaa;
  user-select: none;
  text-transform: uppercase;
}

.cp-btn-container {
  display: flex;
  justify-content: space-evenly;

  & .#{$prefix}btn-prim {
    margin: 2.5px;
  }
}

.gutter {
  cursor: ns-resize;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
}

.gutter:after {
  content: '';
  display: block;
  height: 8px;
  width: 100%;
  position: absolute;
  top: -3px;
  z-index: 150;
}

.code-panel .CodeMirror {
  height: 100%;
}

.cp-delete-css {
  margin-left: 70%;
}

.#{$prefix}pn-views {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0;
}

.#{$prefix}pn-views-container {
  box-shadow: initial;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  top: 40px;
  padding-top: 0;
  height: calc(100% - 40px);
}

.#{$prefix}pn-views-container,
.#{$prefix}cv-canvas {
  transition: width 0.3s ease-in-out;
}

.category-label {
  &.active {
    @apply tw-bg-gray-50 tw-border-t tw-border-b tw-border-gray-200;
  }
}

.gjs-layer-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  // border-bottom: 1px solid var(--gjs-main-dark-color);
  // background-color: var(--gjs-secondary-dark-color);
  // gap: var(--gjs-flex-item-gap);
  cursor: pointer;
}

.gjs-layer-item-left {
  width: 100%;
}
.gjs-layer-item-left {
  // margin-left: 60px;
}
.gjs-layer-item-right {
  // padding-left: 40px;
}
