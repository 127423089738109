.btn-node-type-start {
  @apply tw-border tw-border-green-300 tw-text-green-600 hover:tw-border-green-600 hover:tw-text-green-800 hover:tw-shadow-md;
}
.btn-node-type-wait {
  @apply tw-border tw-border-yellow-300 tw-text-yellow-600 hover:tw-border-yellow-600 hover:tw-text-yellow-800 hover:tw-shadow-md;
}
.btn-node-type-activity {
  @apply tw-border tw-border-blue-300 tw-text-blue-600 hover:tw-border-blue-600 hover:tw-text-blue-800 hover:tw-shadow-md;
}
.btn-node-type-conditional {
  @apply tw-border tw-border-purple-300 tw-text-purple-600 hover:tw-border-purple-600 hover:tw-text-purple-800 hover:tw-shadow-md;
}
.btn-node-type-trigger {
  @apply tw-border tw-border-green-300 tw-text-green-600 hover:tw-border-green-600 hover:tw-text-green-800 hover:tw-shadow-md;
}

.node-content {
  @apply tw-min-w-[200px] tw-bg-white tw-border-b tw-border-r tw-border-l tw-border-gray-300 tw-rounded tw-relative;

  .node-icon-container {
    @apply tw-p-1 tw-bg-white tw-rounded;
  }

  .node-icon {
    @apply tw-w-5 tw-h-5;
  }

  &.node-type-start {
    @apply tw-border-t-4 tw-border-t-green-600;
    .node-icon-container {
      @apply tw-border tw-border-green-300 tw-text-green-600;
    }
  }
  &.node-type-wait {
    @apply tw-border-t-4 tw-border-t-yellow-600;
    .node-icon-container {
      @apply tw-border tw-border-yellow-300 tw-text-yellow-600;
    }
  }
  &.node-type-activity {
    @apply tw-border-t-4 tw-border-t-blue-600;
    .node-icon-container {
      @apply tw-border tw-border-blue-300 tw-text-blue-600;
    }
  }
  &.node-type-conditional {
    @apply tw-border-t-4 tw-border-t-purple-600;
    .node-icon-container {
      @apply tw-border tw-border-purple-300 tw-text-purple-600;
    }
  }
  &.node-type-trigger {
    @apply tw-border-t-4 tw-border-t-green-600;
    .node-icon-container {
      @apply tw-border tw-border-green-300 tw-text-green-600;
    }
  }

  .node-inputs {
    @apply tw-absolute -tw-top-2 tw-left-0 tw-w-full tw-flex;
    .node-input {
    }
  }

  .node-outputs {
    @apply tw-absolute -tw-bottom-2 tw-left-0 tw-w-full tw-flex tw-items-end;
    .node-output {
      @apply tw-w-4 tw-h-4 tw-rounded-sm tw-bg-gray-100 tw-border tw-border-gray-300;
      .remove-connection {
        @apply tw-hidden;
      }
      .add-connection {
        @apply tw-hidden;
      }
      &:hover:not(.f-node-output-connected) .add-connection {
        @apply tw-block;
      }
      &.f-node-output-connected {
        @apply tw-bg-primary-100 tw-border-primary-300;

        .remove-connection {
          @apply tw-block;
        }
      }
    }
  }

  .node-header {
    @apply tw-p-2 tw-flex tw-items-center tw-gap-2 tw-text-sm tw-font-medium tw-relative;
    .node-actions {
      @apply tw-hidden tw-absolute tw-right-2 tw-top-2;

      .node-action-icon {
        @apply tw-flex tw-items-center tw-rounded-full tw-text-gray-400 hover:tw-text-gray-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100;
      }
    }
    .node-info {
      @apply tw-absolute tw-right-2 tw-top-2;

      &.node-info-icon {
        @apply tw-flex tw-items-center tw-rounded-full tw-text-info-400 hover:tw-text-info-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 focus:tw-ring-offset-info-100;
      }
    }
  }

  .node-body {
    @apply tw-p-2;
  }

  &:hover .node-actions,
  &:has(.node-action-icon.open) .node-actions {
    @apply tw-block;
  }
}

.trigger-events,
.activity-details,
.wait-details {
  @apply tw-text-xs tw-text-gray-600;
}

.event-item {
  @apply tw-py-0.5 tw-px-1 tw-bg-gray-100 tw-rounded tw-my-0.5;
}

.f-flow {
  .f-connection-center {
    @apply tw-bg-gray-100 tw-px-2 tw-py-1 tw-rounded tw-whitespace-nowrap tw-border tw-border-gray-200 tw-text-[10px] tw-leading-[10px];
  }

  .f-selection-area {
    @apply tw-bg-[#248eff19] tw-border tw-border-dashed tw-border-[#248eff];
  }

  .f-background {
    line {
      @apply tw-stroke-gray-500;
    }
  }

  .f-line-alignment {
    .f-line {
      @apply tw-bg-gray-500 tw-w-full;
    }
  }

  .f-connection {
    #normal_end {
      @apply tw-fill-gray-400;
    }

    #selected_end {
      @apply tw-fill-primary-300;
    }

    .f-connection-drag-handle {
      @apply tw-fill-transparent;
    }

    .f-connection-selection {
      @apply tw-stroke-[#ffffff73] tw-stroke-[20px] tw-fill-none;
    }

    .f-connection-path {
      @apply tw-stroke-[3px] tw-fill-none tw-stroke-gray-500;
    }

    &.f-selected {
      .f-connection-path {
        @apply tw-stroke-primary-300;
      }

      .f-connection-text {
        @apply tw-stroke-primary-300;
      }

      .f-connection-selection {
        @apply tw-stroke-white;
      }
    }
  }

  .f-node {
    @apply tw-min-w-[240px] tw-relative tw-text-gray-800;

    &.f-selected {
      @apply tw-shadow-lg;
    }
  }

  .f-animated-node {
    @apply tw-bg-[#248eff19];
  }

  .f-animated-connection {
    @apply tw-stroke-[#248eff19] tw-stroke-[12px] tw-fill-none;
  }
}
.f-external-item {
  @apply tw-cursor-move;
}

.f-drag-handle {
  @apply tw-cursor-move;
}

.f-dragging {
  @apply tw-cursor-grabbing;
}
