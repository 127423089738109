.form {
  @apply tw-space-y-8 #{!important};
  p {
    @apply tw-mb-2 #{!important};
  }
}

.form-slide-out {
  @apply tw-flex tw-h-full tw-flex-col tw-divide-y tw-divide-gray-200 tw-bg-white tw-shadow-xl tw-w-full #{!important};
}

.form-body {
  @apply tw-space-y-8 tw-divide-y tw-divide-gray-300 #{!important};
}
.form-body-no-divider {
  @apply #{!important};
}
.grapesjs {
  @apply tw-p-6 #{!important};
  .form-section {
    @apply tw-grid tw-grid-cols-1 tw-gap-x-4 sm:tw-grid-cols-6 #{!important};
  }
  .form-section:first-child {
    @apply tw-min-h-[10rem] #{!important};
  }
}

.form-section:not(:first-child) {
  @apply tw-pt-8 #{!important};
}

.form-section-intro {
  @apply tw-mb-3 tw-pb-2 #{!important};
  ol {
    @apply tw-list-decimal tw-list-inside tw-pl-4 tw-text-sm tw-text-gray-500 #{!important};
    li {
      @apply tw-mb-2 #{!important};
    }
  }
}

.form-section-title {
  @apply tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 #{!important};
}

.form-section-desc {
  @apply tw-mt-1 tw-text-sm tw-text-gray-500 #{!important};
}

.form-section-content {
  @apply tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-4 sm:tw-grid-cols-6 #{!important};
}

.form-group {
}
