:root {
  /* Colors */
  --primary-50: #fee7e7;
  --primary-100: #fccbcb;
  --primary-200: #f99d9d;
  --primary-300: #f26464;
  --primary-400: #e63535;
  --primary-500: #cc1616;
  --primary-600: #a30d0d;
  --primary-700: #7c0f0f;
  --primary-800: #621212;
  --primary-900: #531414;
  --secondary-50: #fff7ed;
  --secondary-100: #ffedd5;
  --secondary-200: #fed7aa;
  --secondary-300: #fdba74;
  --secondary-400: #fb923c;
  --secondary-500: #f97316;
  --secondary-600: #ea580c;
  --secondary-700: #c2410c;
  --secondary-800: #9a3412;
  --secondary-900: #7c2d12;
  --error-50: #fee7e7;
  --error-100: #fccbcb;
  --error-200: #f99d9d;
  --error-300: #f26464;
  --error-400: #e63535;
  --error-500: #cc1616;
  --error-600: #a30d0d;
  --error-700: #7c0f0f;
  --error-800: #621212;
  --error-900: #531414;
  --warn-50: #fffbeb;
  --warn-100: #fef3c7;
  --warn-200: #fde68a;
  --warn-300: #fcd34d;
  --warn-400: #fbbf24;
  --warn-500: #f59e0b;
  --warn-600: #d97706;
  --warn-700: #b45309;
  --warn-800: #92400e;
  --warn-900: #78350f;
  --info-50: #ecfeff;
  --info-100: #e0f2fe;
  --info-200: #bae6fd;
  --info-300: #7dd3fc;
  --info-400: #38bdf8;
  --info-500: #0ea5e9;
  --info-600: #0284c7;
  --info-700: #0369a1;
  --info-800: #075985;
  --info-900: #0c4a6e;
  --success-50: #f0fdf4;
  --success-100: #dcfce7;
  --success-200: #bbf7d0;
  --success-300: #86efac;
  --success-400: #4ade80;
  --success-500: #22c55e;
  --success-600: #16a34a;
  --success-700: #15803d;
  --success-800: #166534;
  --success-900: #14532d;
  --font-custom1: 'Inter Variable';
  --font-custom2: 'Inter Variable';
  --font-menu: 'Inter Variable';
  --font-footer: 'Inter Variable';
  --font-headings: 'Inter Variable';
  --font-copy: 'Inter Variable';
}

@import '../../../libs/ui/src/lib/styles/all/styles.scss';
