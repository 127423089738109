@tailwind base;
@tailwind components;
@tailwind utilities;

@import './abstracts/_abstracts-dir';
@import './components/_components-dir';
@import './layouts/_layouts-dir';

// remove emulator warning
.firebase-emulator-warning {
  display: none;
}
