.input {
  @apply tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm #{!important};
  &.input-error {
    @apply tw-border-error-500 tw-border-2 sm:tw-text-sm #{!important};
  }
}

.input-error-icon {
  @apply tw-h-5 tw-w-5 tw-text-error-500 tw-absolute tw-inset-y-0 tw-right-1 tw-top-1/2 -tw-translate-y-1/2 tw-flex tw-items-center #{!important};
}

.input-no-block {
  @apply tw-appearance-none tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm #{!important};
}

.input-chicklets {
  @apply tw-appearance-none tw-block tw-w-full tw-px-3 tw-pt-1 tw-border tw-border-gray-300 tw-rounded-md tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm #{!important};
  &.input-error {
    @apply tw-border-error-500 tw-border-2 sm:tw-text-sm #{!important};
  }
}

.input-placeholder-dark {
  @apply tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-placeholder-gray-900 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm #{!important};
  &.input-error {
    @apply tw-border-error-500 tw-border-2 sm:tw-text-sm #{!important};
  }
}

.input-pl-7 {
  @apply tw-appearance-none tw-block tw-w-full tw-pr-3 tw-pl-7 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm #{!important};
  &.input-error {
    @apply tw-border-error-500 tw-border-2 sm:tw-text-sm  #{!important};
  }
}

.input-wrapper {
  @apply tw-relative tw-flex tw-rounded-md tw-shadow-sm sm:tw-text-sm  #{!important};
  &.input-error {
    @apply tw-border-error-500 tw-border-2 sm:tw-text-sm  #{!important};
  }
}

.input-wrapper-w-fit {
  @apply tw-relative tw-flex tw-w-fit tw-rounded-md tw-shadow-sm  sm:tw-text-sm #{!important};
  &.input-error {
    @apply tw-border-error-500 tw-border-2 sm:tw-text-sm #{!important};
  }
}

.input-with-prefix {
  @apply tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-none tw-rounded-r-md  tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm #{!important};
}

.input-with-prefix-w-fit {
  @apply tw-appearance-none tw-block tw-w-fit tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-none tw-rounded-r-md  tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm #{!important};
}

.input-with-suffix {
  @apply tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-none tw-rounded-l-md  tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 sm:tw-text-sm #{!important};
}

.input-with-suffix-w-fit {
  @apply tw-appearance-none tw-block tw-w-fit tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-none tw-rounded-l-md  tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500 tw-text-sm #{!important};
}

.input-prefix {
  @apply tw-whitespace-nowrap tw-inline-flex tw-items-center tw-rounded-l-md tw-border  tw-border-r-0 tw-border-gray-300 tw-bg-gray-50 tw-px-3 tw-text-gray-500 tw-text-sm #{!important};
}

.input-suffix {
  @apply tw-inline-flex tw-items-center tw-rounded-r-md tw-border  tw-border-l-0 tw-border-gray-300 tw-bg-gray-50 tw-px-3 tw-text-gray-500 sm:tw-text-sm #{!important};
}

.input-suffix-btn {
  @apply tw-relative -tw-ml-px tw-inline-flex tw-items-center  tw-space-x-2 tw-rounded-r-md tw-border tw-border-gray-300  tw-border-l-0 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-border-gray-300 focus:tw-border-primary-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-primary-500 #{!important};
}

.input-label {
  @apply tw-block tw-text-sm tw-font-medium tw-text-gray-700 #{!important};
}

.input-label-xs-muted {
  @apply tw-block tw-text-xs tw-font-medium tw-text-gray-500 #{!important};
}

.input-label-flex {
  @apply tw-w-full tw-flex tw-flex-row tw-text-sm tw-font-medium tw-items-center tw-text-gray-700 #{!important};
}

.input-error {
  @apply tw-block tw-text-xs tw-font-medium tw-text-error-500 #{!important};
}

.input-checkbox {
  @apply tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-primary-600 focus:tw-ring-primary-600 #{!important};
}

.file-upload-overlay {
  @apply tw-hidden tw-box-border tw-flex tw-flex-col tw-justify-center tw-items-center tw-opacity-0 tw-fixed tw-min-w-full tw-min-h-full tw-p-0 tw-top-0 tw-left-0 -tw-z-50 tw-transition-all tw-duration-150 tw-ease-in-out tw-bg-neutral-400 tw-outline-offset-[-15px] #{!important};
  sidkik-icon {
    @apply tw-w-1/6 tw-max-w-[150px] tw-max-h-[150px] tw-h-1/6 tw-inline-block tw-transition-all tw-duration-700 tw-ease-in-out tw-pointer-events-none #{!important};
  }
  tw-h3 {
    @apply tw-pointer-events-none #{!important};
  }
  &.fileover {
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-opacity-100 tw-z-50 tw-transition-all tw-duration-150 tw-ease-in-out tw-outline-offset-[-25px] tw-bg-neutral-50 tw-outline-dashed tw-outline-2 tw-outline-neutral-500 #{!important};
    sidkik-icon {
      @apply tw-w-1/5 tw-max-w-[170px] tw-max-h-[170px] tw-h-1/5 tw-inline-block tw-transition-all tw-duration-700 tw-ease-in-out tw-text-neutral-600 #{!important};
    }
  }
}

.sidkik-option {
  @apply tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-8 tw-pr-4 tw-text-gray-900 #{!important};
  .sidkik-option-selected-icon {
    @apply tw-absolute tw-hidden tw-inset-y-0 tw-left-0 tw-items-center tw-pl-1.5 tw-text-primary-600 #{!important};
  }
  &[aria-selected='true'] {
    .sidkik-option-selected-icon {
      @apply tw-flex #{!important};
    }
  }
}
.sidkik-option-label {
  @apply tw-block tw-truncate sm:tw-text-sm #{!important};
}

.sidkik-option-plus {
  @apply tw-relative hover:tw-bg-primary-600 hover:tw-text-white tw-cursor-default tw-select-none tw-p-3 tw-text-gray-900 tw-text-sm #{!important};
  .sidkik-option-selected-icon-plus {
    @apply tw-hidden tw-text-primary-500 #{!important};
  }
  &[aria-selected='true'] {
    .sidkik-option-selected-icon-plus {
      @apply tw-block tw-text-primary-500 #{!important};
    }
    .sidkik-option-label-plus {
      @apply tw-block tw-font-semibold tw-truncate hover:tw-text-white sm:tw-text-sm #{!important};
    }
  }
  &:hover {
    .sidkik-option-selected-icon-plus {
      @apply tw-text-white #{!important};
    }
    .sidkik-option-description-plus {
      @apply tw-mt-2 tw-text-white #{!important};
    }
  }
  .sidkik-option-label-plus {
    @apply tw-block tw-truncate tw-font-normal  sm:tw-text-sm #{!important};
  }
  .sidkik-option-description-plus {
    @apply tw-mt-2 tw-text-gray-500 #{!important};
  }
}

[multiple] {
  @apply tw-border-0 #{!important};
}

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid #{!important};
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) ' ' #{!important};

  /* This is how textarea text behaves */
  white-space: pre-wrap #{!important};

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden #{!important};
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none #{!important};

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden #{!important};
}
.grow-wrap > textarea,
.grow-wrap::after {
  @apply tw-border-gray-300 tw-border-[1px] tw-p-1 #{!important};

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2 #{!important};
}

input[type='range']::-webkit-slider-thumb {
  @apply tw-bg-primary-500 tw-h-4 tw-w-4 tw-rounded-full tw-shadow tw-cursor-pointer tw-appearance-none #{!important};
}
