sidkik-event-year-view {
  .twentyeightdays {
    @apply tw-grid;
    grid-template-columns: repeat(28, minmax(0, 1fr));
  }
  .twentyninedays {
    @apply tw-grid;
    grid-template-columns: repeat(29, minmax(0, 1fr));
  }
  .thirtydays {
    @apply tw-grid;
    grid-template-columns: repeat(30, minmax(0, 1fr));
  }
  .thirtyonedays {
    @apply tw-grid;
    grid-template-columns: repeat(31, minmax(0, 1fr));
  }
}

sidkik-shop-calendar-view,
sidkik-booking-change-popup,
sidkik-calendar-availability-view {
  .fc-multiMonthFourMonth-view {
    .fc-daygrid-event-harness {
      @apply tw-absolute tw-h-40 tw-m-0;
    }
    .fc-daygrid-day-bottom {
      @apply tw-h-full tw-w-full;
      .fc-daygrid-more-link {
        @apply tw-w-full tw-flex tw-items-center tw-justify-center;
      }
    }
  }
}

.fc {
  .fc-view {
    @apply tw-bg-white #{!important};
  }
  .fc-header-toolbar {
    @apply tw-hidden #{!important};
  }
  .fc-button-primary {
    @apply tw-bg-primary-600  tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-leading-4 tw-text-white tw-shadow-sm  hover:tw-bg-primary-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 #{!important};
  }
  .fc-col-header-cell {
    @apply tw-text-gray-500 tw-text-xs tw-font-medium tw-py-3 #{!important};
  }
  .fc-daygrid-day {
    @apply tw-text-xs sm:tw-text-sm tw-font-normal #{!important};
  }
  .fc-daygrid-day-top {
    @apply tw-text-xs tw-font-normal tw-flex-row tw-pt-1  #{!important};
  }
  .fc-h-event {
    @apply tw-bg-primary-300 tw-px-1 tw-border-primary-400 #{!important};
  }
  .fc-daygrid-dot-event {
    @apply tw-px-1 hover:tw-bg-transparent #{!important};
  }
  .fc-daygrid-day {
    .fc-daygrid-day-number {
      @apply tw-ml-[2px] tw-w-[22px] tw-h-[22px] tw-flex tw-items-center tw-justify-center #{!important};
    }
    &.fc-day-today {
      @apply tw-bg-transparent #{!important};
      .fc-daygrid-day-top {
        @apply #{!important};
        .fc-daygrid-day-number {
          @apply tw-text-white tw-rounded-full tw-bg-primary-600 #{!important};
        }
      }
    }
  }
  .fc-timeGridWeek-view,
  .fc-timeGridDay-view {
    .fc-timegrid-body {
      .fc-timegrid-slots {
        .fc-timegrid-slot {
          @apply tw-h-[4rem] #{!important};
        }
        .fc-timegrid-slot-label {
          @apply tw-block tw-text-xs tw-font-normal tw-text-gray-500 tw-py-1 tw-px-0.5 tw-border-r-0 tw-border-l-0 tw-border-t-0 tw-border-b-0 -tw-mt-[.9rem] #{!important};
        }
        .fc-timegrid-slot-minor {
          &.fc-timegrid-slot-lane {
            @apply tw-border-t tw-border-solid #{!important};
          }
        }
      }
    }
  }
  .fc-listWeek-view {
    .fc-list-event-time {
      @apply tw-font-normal tw-text-gray-500 tw-mr-2 tw-mt-1 #{!important};
    }
    .fc-list-event-dot {
      @apply tw-w-2 tw-h-2 tw-rounded-full tw-bg-primary-500 tw-mr-2 tw-mt-1 tw-border-2 tw-border-white #{!important};
    }
    .fc-list-day-text {
      @apply tw-text-sm tw-font-normal tw-text-gray-500 #{!important};
    }
    .fc-list-day-side-text {
      @apply tw-text-sm tw-font-normal tw-text-gray-500 #{!important};
    }
  }
  .fc-timegrid-axis-frame {
    @apply tw-text-xs tw-font-normal tw-text-gray-500 #{!important};
  }
  .fc-v-event {
    @apply tw-bg-primary-100 tw-px-1 tw-border-primary-100 #{!important};
    &:hover {
      @apply tw-bg-primary-200 tw-border-primary-200 #{!important};
    }
  }
  .fc-day-today {
    @apply tw-bg-gray-100  #{!important};
  }
  .fc-timegrid-now-indicator-arrow {
    @apply tw-border-primary-800 tw-border-t-transparent tw-border-b-transparent #{!important};
  }
  .fc-timegrid-now-indicator-line {
    @apply tw-border-primary-800  #{!important};
  }
  // .fc-timegrid-slot-minor {
  //   @apply tw-border-t-0 #{!important};
  // }
}

// /* Toolbar */
// .fc-toolbar-chunk {
//   .btn {
//     @apply tw-inline-flex tw-items-center tw-ring-0 tw-border-none tw-rounded-md tw-bg-primary-400 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white hover:tw-bg-primary-500 tw-shadow-none #{!important};
//   }
//   .btn:disabled {
//     @apply tw-bg-primary-200 tw-cursor-auto #{!important};
//   }
//   .btn:not(:disabled):active {
//     @apply tw-bg-primary-600 #{!important};
//   }
//   .btn:not(:disabled):focus {
//     @apply tw-shadow-none #{!important};
//   }
//   .btn:not(:disabled):active:focus {
//     @apply tw-shadow-none #{!important};
//   }
//   .fc-today-button:disabled {
//     @apply tw-bg-gray-800/50 #{!important};
//   }

//   // .fc-today-button.fc-button-primary:not(.fc-today-button:disabled) {
//   //   @apply tw-bg-primary-900 hover:tw-bg-gray-950 tw-transition-colors #{!important};
//   // }
// }

// /* Event calendar */
// .fc-event {
//   @apply tw-bg-primary-400 tw-border-primary-400 #{!important};
// }

// /* Non business hours */
// .fc-non-business {
//   @apply tw-bg-gray-400 tw-bg-opacity-30 #{!important};
// }

/*
  FULLCALENDAR 
*/

// .fc-button {
//   @apply py-1 px-2.5 bg-growp-500 hover:bg-gray-950 m-0 h-[36px] border-none focus:ring-1 focus:ring-growp-900 focus:outline-1 focus:outline-growp-900 !important
// }

// .fc-button-group {
//   @apply space-x-0 !important
// }

// .fc-toolbar-chunk {
//   @apply space-x-4 !important
// }

// .fc-today-button:disabled {
//   @apply bg-gray-800/50 !important
// }

// .fc-today-button.fc-button-primary:not(.fc-today-button:disabled) {
//   @apply bg-growp-900 hover:bg-gray-950 transition-colors !important
// }

// .fc-theme-standard td,
// .fc-theme-standard th,
// .fc-theme-standard .fc-scrollgrid {
//   @apply border-gray-600/10 !important
// }

// .fc-button.fc-button-primary.fc-button-active {
//   @apply bg-gray-800 !important
// }

// .fc-toolbar-title {
//   @apply text-center text-xl font-semibold !important
// }

// .fc-day.fc-day-fri.fc-day-today.fc-daygrid-day {
//   @apply bg-sky-900/60 !important
// }

// .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
//   @apply w-full md:min-w-[450px] !important
// }

// .fc-highlight {
//   @apply bg-sky-400/10 !important
// }

// .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
//   @apply flex md:flex-row flex-col-reverse md:items-center items-start md:justify-between justify-start gap-2 !important
// }

// .fc-popover {
//   @apply z-[1] !important
// }

// /* Fullcalendar visualização em lista */
// .fc .fc-list-sticky .fc-list-day > th {
//   @apply bg-growp-500 text-white !important
// }
// .fc-theme-standard .fc-list-day-cushion{
//   @apply bg-none !important
// }
// .fc-listMonth-view,
// .fc-dayGridWeek-view.fc-view.fc-daygrid {
//   @apply bg-transparent text-gray-500 !important
// }

// .fc-list-event:hover {
//   @apply text-growp-500 cursor-pointer !important
// }

// .fc-dayGridDay-view.fc-view.fc-daygrid .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
//   @apply bg-gray-900 !important
// }

// .fc-popover-header {
//   @apply bg-gray-900 text-gray-400 !important
// }

// .fc-popover-body {
//   @apply bg-gray-900 !important
// }
