.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-slide-out-pane {
  @apply tw-h-full tw-w-full sm:tw-w-96 md:tw-w-7/12;
}
.cdk-overlay-pane-absolute-dd {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-pane-absolute {
  min-width: 30vw;
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-pane-absolute-media {
  margin-left: 2.5%;
  margin-top: 2.5%;
  min-width: 95%;
  min-height: 95%;
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}
.cdk-overlay-transparent-backdrop {
  background: rgba(255, 255, 255, 0.5);
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  backdrop-filter: blur(2px);
  visibility: visible;
}
.cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.tooltip-container {
  // Prevent text selection
  user-select: none;
  -webkit-user-select: none;

  .tooltip-content {
    @apply tw-max-w-xs tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-white 
           tw-bg-gray-900 tw-rounded-md tw-shadow-sm tw-z-[9999];

    // Mobile optimizations
    @media (pointer: coarse) {
      @apply tw-px-4 tw-py-3 tw-text-base tw-max-w-[90vw];
    }

    .tooltip-arrow {
      @apply tw-absolute tw-w-0 tw-h-0;
      border: 5px solid transparent;

      :host-context(.top) & {
        @apply tw-bottom-[-10px] tw-left-1/2 tw--translate-x-1/2;
        border-top-color: theme('colors.gray.900');
      }

      :host-context(.bottom) & {
        @apply tw-top-[-10px] tw-left-1/2 tw--translate-x-1/2;
        border-bottom-color: theme('colors.gray.900');
      }

      :host-context(.left) & {
        @apply tw-right-[-10px] tw-top-1/2 tw--translate-y-1/2;
        border-left-color: theme('colors.gray.900');
      }

      :host-context(.right) & {
        @apply tw-left-[-10px] tw-top-1/2 tw--translate-y-1/2;
        border-right-color: theme('colors.gray.900');
      }
    }

    .tooltip-inner {
      @apply tw-relative tw-z-10;

      // Handle long content on mobile
      @media (pointer: coarse) {
        @apply tw-break-words tw-text-center;
      }
    }
  }
}
