/** tw-tags tw-list **/
.tags-list {
  @apply tw-my-8 tw-flex tw-flex-wrap tw-justify-center tw-space-x-4;
  .tag-container {
    @apply tw-my-2 tw-pointer-events-auto tw-relative hover:tw-shadow-md tw-inline-flex tw-rounded-md tw-bg-neutral-300 tw-text-[0.8125rem] tw-font-medium tw-leading-5 tw-text-neutral-800 tw-shadow-sm tw-ring-1 tw-ring-neutral-700/10 hover:tw-bg-neutral-700 hover:tw-text-neutral-50;
    tw-svg {
      @apply tw-mr-2.5 tw-h-5 tw-w-5 tw-flex-none tw-fill-neutral-200;
    }
    &.active {
      @apply tw-bg-neutral-700 tw-text-neutral-200 tw-shadow-md;
      tw-svg {
        @apply tw-fill-neutral-500;
      }
    }
  }
}
