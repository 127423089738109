.page-content {
  @apply tw-py-6 tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-relative;
}

.help-button {
  @apply tw-absolute tw-top-8 tw-right-8;
}

.constrained {
  @apply tw-mx-auto tw-max-w-7xl tw-px-3 sm:tw-px-8 lg:tw-px-10;
}
