.page-header-top-half {
  @apply tw-h-6 tw-flex tw-bg-primary-900 tw-pt-4 tw-pl-3 md:tw-pl-8 tw-pr-8 tw-pb-2;
  &.intersecting {
    // @apply tw-h-6 tw-flex tw-bg-error-900 tw-pt-4 tw-pl-3 md:tw-pl-8 tw-pr-8 tw-pb-2;
  }
}

.page-header-top-half-breadcrumb-list {
  @apply tw-flex tw-items-center tw-space-x-4;
}

.page-header-top-half-breadcrumb-list-home {
  @apply tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary-200;
}

.page-header-top-half-breadcrumb-list-item-clickable {
  @apply tw-text-sm tw-font-medium tw-text-primary-200 hover:tw-text-primary-100 tw-cursor-pointer;
}

.page-header-top-half-breadcrumb-list-item-not-clickable {
  @apply tw-text-sm tw-font-medium tw-text-primary-200 tw-cursor-default;
}

.page-header-bottom-half {
  @apply tw-z-50 tw-sticky tw-pt-2 tw-top-0 tw-h-full tw-px-3 md:tw-px-6 md:tw-py-5 tw-bg-primary-900;
  &.intersecting {
    // @apply tw-pb-0 tw-pt-2 tw-transition-all tw-ease-in tw-shadow-sm tw-duration-300;
  }
}

.page-header-bottom-container {
  @apply tw-flex tw-items-center tw-justify-between tw-pb-2 md:tw-pb-0;
}

.page-header-bottom-title-container {
  @apply tw-min-w-0 tw-flex-1;
}

.page-header-bottom-title-h2 {
  @apply tw-text-2xl tw-font-bold  tw-text-white sm:tw-truncate sm:tw-text-3xl sm:tw-leading-[3rem] sm:tw-tracking-tight;
}

h1.sidkik-header {
  @apply tw-font-headings tw-text-3xl tw-font-medium;
}
h2.sidkik-header {
  @apply tw-font-headings tw-text-2xl tw-font-medium;
}
h3.sidkik-header {
  @apply tw-font-headings tw-text-xl tw-font-medium;
}
h4.sidkik-header {
  @apply tw-font-headings tw-text-lg tw-font-medium;
}

.sidkik-h2,
.sidkik-h3,
.sidkik-h4,
.sidkik-h5,
.sidkik-h6 {
  @apply tw-font-headings;
}

.header-nav-item {
  @apply tw-relative tw-text-primary-900 tw-flex tw-items-center tw-px-4 tw-py-1 tw-mb-0.5 tw-text-base tw-font-semibold tw-border-r-4 tw-border-transparent;

  &.active {
    .header-nav-item-link {
      @apply before:tw-h-[2px] before:tw-scale-x-100 before:tw-absolute before:tw-left-0 before:tw-bottom-0 before:tw-bg-primary-700;
    }
  }
  svg {
    @apply tw-text-gray-400 hover:tw-text-gray-500 tw-mr-5 tw-h-6 tw-w-6;
    &.alert {
      @apply tw-text-info-500 tw-mr-0 tw-h-5 tw-w-5;
    }
  }
  .header-nav-item-link {
    @apply tw-relative before:tw-w-full before:tw-h-[2px] before:tw-origin-right before:tw-transition-transform before:tw-duration-700 before:tw-scale-x-0 before:tw-bg-primary-700 before:tw-absolute before:tw-left-0 before:tw-bottom-0;
    &.more {
      @apply after:tw-absolute after:-tw-right-5 after:tw-inline-block after:tw-mt-2 after:tw-border-primary-700 after:tw-border-l-2 after:tw-border-t-2 after:tw-scale-0 after:tw-w-2 after:tw-h-2 after:tw-transform after:tw-rotate-[135deg] after:tw-duration-300 after:tw-ease-in-out;
    }
  }
}
.header-nav-item:hover {
  .header-nav-item-link {
    @apply before:tw-scale-x-100 before:tw-origin-left before:tw-duration-300;
    &.more {
      @apply after:tw-scale-100 after:tw-text-primary-700;
    }
  }
}
