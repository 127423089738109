.btn-primary {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-primary-600  tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-white tw-shadow-sm  hover:tw-bg-primary-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply -tw-mr-1 tw-ml-2 tw-h-5 tw-w-5 #{!important};
  }
  &.active {
    @apply tw-underline;
  }
}

.btn-sub-primary {
  @apply tw-flex tw-justify-center tw-items-center hover:tw-bg-primary-500 hover:tw-text-white tw-rounded tw-border tw-border-primary-500 tw-bg-transparent  tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-primary-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-700 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply -tw-mr-1 tw-ml-2 tw-h-5 tw-w-5 #{!important};
  }
  &.active {
    @apply tw-underline;
  }
}

.btn-secondary {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-secondary-200 tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-secondary-500 tw-shadow-sm  hover:tw-bg-secondary-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply -tw-mr-1 tw-ml-2 tw-h-5 tw-w-5 #{!important};
  }
  &.active {
    @apply tw-underline;
  }
}

.btn-neutral {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-neutral-200 tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-neutral-700 tw-shadow-sm  hover:tw-bg-neutral-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply -tw-mr-1 tw-ml-2 tw-h-5 tw-w-5 #{!important};
  }
  &.active {
    @apply tw-underline;
  }
}

.btn-icon-neutral {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-neutral-200 tw-text-neutral-500 tw-shadow-sm  hover:tw-bg-neutral-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply tw-h-3.5 tw-w-3.5 tw-m-1.5 #{!important};
  }
}

.btn-icon-no-current-color {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-bg-neutral-100  hover:tw-bg-neutral-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply tw-h-3.5 tw-w-3.5 tw-m-1.5 #{!important};
  }
}

.btn-highlight {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-secondary-500  tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-white tw-shadow-sm  hover:tw-bg-secondary-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-secondary-600 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply -tw-mr-1 tw-ml-2 tw-h-5 tw-w-5 #{!important};
  }
}

.btn-error {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-error-600  tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-white tw-shadow-sm  hover:tw-bg-error-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-error-500 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply -tw-mr-1 tw-ml-2 tw-h-5 tw-w-5 #{!important};
  }
}

.btn-warn {
  @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-error-600  tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-white tw-shadow-sm  hover:tw-bg-error-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-error-500 focus:tw-ring-offset-2 #{!important};
  .icon {
    @apply -tw-mr-1 tw-ml-2 tw-h-5 tw-w-5 #{!important};
  }
}

.button-toggle-checked {
  .btn-neutral {
    @apply tw-flex tw-justify-center tw-items-center tw-rounded tw-border tw-border-transparent tw-bg-neutral-200 tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-leading-4 tw-text-primary-600 tw-shadow-sm  hover:tw-bg-neutral-300 focus:tw-outline-none tw-ring-2 tw-ring-primary-500 tw-ring-offset-2 #{!important};
    .icon {
      @apply -tw-mr-1 tw-ml-2 tw-h-5 tw-w-5 #{!important};
    }
  }
}
