.table-standard {
  @apply tw-min-w-full tw-divide-y tw-divide-gray-300 tw-overflow-x-auto;
  thead {
    @apply tw-bg-gray-50;
  }
  tbody {
    @apply tw-divide-y tw-divide-gray-200 tw-bg-white;
  }
  .header-th {
    @apply tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-6;
  }
  .action-header-th {
    @apply tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-6;
  }
  .cell {
    @apply tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm sm:tw-pl-6;
  }
  .action-cell {
    @apply tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-6 tw-flex tw-justify-end;
  }
}

.table-loading {
  @apply tw-min-w-full tw-divide-y tw-divide-gray-300;
  thead {
    @apply tw-bg-gray-50;
  }
  tbody {
    @apply tw-divide-y tw-divide-gray-200 tw-bg-white;
  }
  .header-th {
    @apply tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-6;
  }
  .action-header-th {
    @apply tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-6;
  }
  .cell {
    @apply tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm sm:tw-pl-6;
  }
  .action-cell {
    @apply tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-6;
  }
}
