g[ngx-charts-axis-label] text {
  @apply tw-text-xs;
}

g[ngx-charts-axis-label] {
  @apply tw-text-xs;
}

g[ngx-charts-grid-lines] line {
  stroke-opacity: 0.1;
}

.x-axis-minimal {
  .x {
    g[ngx-charts-axis-label] text {
      @apply tw-hidden;
    }
  }
  .gridline-path-vertical {
    display: none !important;
  }
  g[ngx-charts-x-axis-ticks] g text {
    transform: rotate(-45deg) !important;
    text-anchor: end;
  }
}
