.sidebar-item-main {
  @apply tw-relative tw-text-primary-900 tw-flex tw-items-center tw-px-4 tw-py-1 tw-mb-0.5 tw-text-base tw-font-semibold tw-border-r-4 tw-border-transparent;

  &.active {
    .sidebar-item-main-link {
      @apply before:tw-h-[2px] before:tw-scale-x-100 before:tw-absolute before:tw-left-0 before:tw-bottom-0 before:tw-bg-primary-700;
    }
  }
  svg {
    @apply tw-text-gray-400 hover:tw-text-gray-500 tw-mr-5 tw-h-6 tw-w-6;
    &.alert {
      @apply tw-text-info-500 tw-mr-0 tw-h-5 tw-w-5;
    }
  }
  .sidebar-item-main-link {
    @apply tw-relative before:tw-w-full before:tw-h-[2px] before:tw-origin-right before:tw-transition-transform before:tw-duration-700 before:tw-scale-x-0 before:tw-bg-primary-700 before:tw-absolute before:tw-left-0 before:tw-bottom-0;
    &.more {
      @apply after:tw-absolute after:-tw-right-5 after:tw-inline-block after:tw-mt-2 after:tw-border-primary-700 after:tw-border-l-2 after:tw-border-t-2 after:tw-scale-0 after:tw-w-2 after:tw-h-2 after:tw-transform after:tw-rotate-[135deg] after:tw-duration-300 after:tw-ease-in-out;
    }
  }
}
.sidebar-item-main:hover {
  .sidebar-item-main-link {
    @apply before:tw-scale-x-100 before:tw-origin-left before:tw-duration-300;
    &.more {
      @apply after:tw-scale-100 after:tw-text-primary-700;
    }
  }
}

.sidebar-item {
  @apply tw-text-gray-500 tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-base tw-font-medium tw-border-r-4 tw-border-transparent;
  &.active {
    @apply tw-bg-gray-200 tw-text-gray-800 tw-border-r-primary-500 tw-border-r-4;
  }
  svg {
    @apply tw-text-gray-400 hover:tw-text-gray-500 tw-mr-5 tw-h-6 tw-w-6;
    &.alert {
      @apply tw-text-info-500 tw-mr-0 tw-h-5 tw-w-5;
    }
  }
}

.sidebar-item:hover {
  @apply tw-bg-gray-50 tw-text-gray-800 tw-border-r-primary-500 tw-border-r-4;
  &.active {
    @apply tw-bg-gray-200 tw-text-gray-900 tw-border-r-primary-500 tw-border-r-4;
  }
  svg {
    @apply tw-text-gray-500;
    &.alert {
      @apply tw-text-info-500 tw-mr-0 tw-h-5 tw-w-5;
    }
  }
}

.sidebar-section {
  @apply tw-px-4 tw-text-[.67rem] tw-uppercase tw-text-gray-500 tw-mb-2 tw-font-medium tw-tracking-wide;
  &:not(:first-child) {
    @apply tw-mt-6;
  }
}

.desktop-sidebar {
  @apply tw-hidden lg:tw-flex lg:tw-flex-grow lg:tw-w-[16rem] tw-overflow-x-hidden;
}

.sidebar-main {
  @apply tw-transition-all tw-duration-300 tw-w-full tw-z-0;
  .sidebar-main-content {
    @apply tw-w-[16rem] tw-overflow-hidden;
  }

  &.minimized {
    @apply tw-w-0 tw-overflow-hidden;
  }
}

.sidebar-subsection {
  @apply tw-transition-all tw-duration-300 tw-w-full tw-h-full tw-bg-white tw-z-10;
  .sidebar-subsection-content {
    @apply tw-w-[16rem] tw-overflow-hidden tw-h-full;
  }
  &.minimized {
    @apply tw-w-0 tw-overflow-hidden;
  }
}
