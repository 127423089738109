.progress-wrapper {
  @apply tw-relative tw-text-primary-500;
  .current {
    @apply tw-absolute tw-text-gray-700 tw-text-xs sm:tw-text-sm md:tw-text-base;
  }
}
sidkik-circle-progress {
  @apply tw-block tw-relative tw-overflow-hidden;
  &.responsive {
    @apply tw-w-full tw-pb-[100%];
    svg {
      @apply tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0;
    }
  }
}

.nav-active {
  .nav-active-pie {
    @apply tw-rounded-full tw-ring-[1px] tw-ring-offset-[2px] tw-ring-offset-primary-500 tw-ring-primary-200 tw-shadow-lg;
  }
}
