.badge-primary-border {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-primary-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-primary-600 tw-ring-1 tw-ring-inset tw-ring-primary-500 #{!important};
}

.badge-secondary-border {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-secondary-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-secondary-600 tw-ring-1 tw-ring-inset tw-ring-secondary-500 #{!important};
}

.badge-success-border {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-success-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-success-600 tw-ring-1 tw-ring-inset tw-ring-success-500 #{!important};
}

.badge-error-border {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-error-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-error-600 tw-ring-1 tw-ring-inset tw-ring-error-500 #{!important};
}

.badge-warn-border {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-warn-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-warn-600 tw-ring-1 tw-ring-inset tw-ring-warn-500 #{!important};
}

.badge-info-border {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-info-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-info-600 tw-ring-1 tw-ring-inset tw-ring-info-500 #{!important};
}

.badge-neutral-border {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-neutral-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-neutral-600 tw-ring-1 tw-ring-inset tw-ring-neutral-500 #{!important};
}

.badge-primary-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-primary-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-primary-600 #{!important};
}

.badge-secondary-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-secondary-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-secondary-600 #{!important};
}

.badge-success-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-success-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-success-600 #{!important};
}

.badge-neutral-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-neutral-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-neutral-600 #{!important};
}

.badge-error-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-error-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-error-600 #{!important};
}

.badge-warn-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-warn-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-warn-600 #{!important};
}

.badge-info-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-md tw-bg-info-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-info-600 #{!important};
}

.pill-primary-border {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-primary-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-primary-600 tw-ring-1 tw-ring-inset tw-ring-primary-500 #{!important};
}

.pill-secondary-border {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-secondary-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-secondary-600 tw-ring-1 tw-ring-inset tw-ring-secondary-500 #{!important};
}

.pill-success-border {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-success-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-success-600 tw-ring-1 tw-ring-inset tw-ring-success-500 #{!important};
}

.pill-error-border {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-error-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-error-600 tw-ring-1 tw-ring-inset tw-ring-error-500 #{!important};
}

.pill-warn-border {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-warn-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-warn-600 tw-ring-1 tw-ring-inset tw-ring-warn-500 #{!important};
}

.pill-info-border {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-info-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-info-600 tw-ring-1 tw-ring-inset tw-ring-info-500 #{!important};
}

.pill-neutral-border {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-neutral-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-neutral-600 tw-ring-1 tw-ring-inset tw-ring-neutral-500 #{!important};
}

.pill-primary-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-primary-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-primary-600 #{!important};
}

.pill-secondary-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-secondary-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-secondary-600 #{!important};
}

.pill-success-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-success-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-success-600 #{!important};
}

.pill-error-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-error-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-error-600 #{!important};
}

.pill-warn-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-warn-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-warn-600 #{!important};
}

.pill-info-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-info-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-info-600 #{!important};
}

.pill-neutral-flat {
  @apply tw-inline-flex tw-items-center tw-rounded-full tw-bg-neutral-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-neutral-600 #{!important};
}

.header-pill-primary-flat {
  @apply tw-mb-0.5 tw-font-semibold tw-inline-flex tw-items-center tw-rounded-full tw-bg-primary-100 tw-px-2 tw-py-1 tw-text-primary-600 hover:tw-bg-primary-200 hover:tw-text-primary-700 #{!important};
  &.active {
    @apply tw-underline;
  }
}

.header-pill-secondary-flat {
  @apply tw-mb-0.5 tw-font-semibold tw-inline-flex tw-items-center tw-rounded-full tw-bg-secondary-100 tw-px-2 tw-py-1 tw-text-secondary-600 hover:tw-bg-secondary-200 hover:tw-text-secondary-700 #{!important};
  &.active {
    @apply tw-underline;
  }
}

.header-pill-neutral-flat {
  @apply tw-mb-0.5 tw-font-semibold tw-inline-flex tw-items-center tw-rounded-full tw-bg-neutral-100 tw-px-2 tw-py-1 tw-text-neutral-600 hover:tw-bg-neutral-200 hover:tw-text-neutral-700 #{!important};
  &.active {
    @apply tw-underline;
  }
}

.header-pill-primary-border {
  @apply tw-mb-0.5 tw-font-semibold tw-inline-flex tw-items-center tw-rounded-full tw-bg-primary-50 tw-px-2 tw-py-1 tw-text-primary-600 tw-ring-1 tw-ring-inset tw-ring-primary-500 #{!important};
  &.active {
    @apply tw-underline;
  }
}

.header-pill-secondary-border {
  @apply tw-mb-0.5 tw-font-semibold tw-inline-flex tw-items-center tw-rounded-full tw-bg-secondary-50 tw-px-2 tw-py-1 tw-text-secondary-600 tw-ring-1 tw-ring-inset tw-ring-secondary-500 #{!important};
  &.active {
    @apply tw-underline;
  }
}

.header-pill-neutral-border {
  @apply tw-mb-0.5 tw-font-semibold tw-inline-flex tw-items-center tw-rounded-full tw-bg-neutral-50 tw-px-2 tw-py-1 tw-text-neutral-600 tw-ring-1 tw-ring-inset tw-ring-neutral-500 #{!important};
  &.active {
    @apply tw-underline;
  }
}
