.workflow-workspace {
  .workflow-step-wrapper[workflow-drop-hover]::after {
    background: #a3e798 !important;
  }

  .workflow-step-wrapper[workflow-drop-hover]::before {
    background: #4cd137 !important;
  }

  .root-node-without-children {
    @apply tw-min-w-[4rem] tw-min-h-[4rem] tw-duration-1000;
  }

  .root-node-with-children {
    @apply tw-min-w-[1rem] tw-min-h-[1rem] tw-duration-1000 tw-bg-gray-50;
  }

  .workflow-arrow {
    & #arrowhead {
      fill: darkgrey;
    }

    & #arrowpath {
      stroke: darkgrey;
    }
  }

  #canvas {
    height: 100vh;
    overflow: auto;
    background: #f0f0f0;
    flex: 1;
  }

  div#canvas[disabled='true'] {
    opacity: 0.7;
  }
  .workflow-canvas {
    overflow: auto;
    display: flex;
  }

  .workflow-canvas-content.scaling .workflow-step-wrapper,
  .workflow-canvas-content.scaling svg,
  .workflow-canvas-content.scaling ng-flowchart-connector-pad {
    transition: none !important;
  }

  .workflow-canvas-content {
    position: relative;
    min-height: 100%;
    min-width: 100%;
    flex: 1 1 100%;
  }

  .workflow-step-wrapper {
    height: auto;
    width: auto;
    position: absolute;
    box-sizing: border-box;

    transition: all 0.2s;

    // cursor: grab;
  }
  .workflow-step-wrapper[draggable] {
    cursor: grab;
  }

  .workflow-step-wrapper[workflow-drop-hover]::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    background: darkred;
  }

  .workflow-step-wrapper[workflow-drop-hover]::after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    z-index: 0;
    background: rgb(192, 123, 123);
    //TODO mixin so theme can be set
    animation: backgroundOpacity 2s linear infinite;
  }

  .workflow-step-wrapper:not(.horizontal)[workflow-drop-hover='above']::before,
  .workflow-step-wrapper:not(.horizontal)[workflow-drop-hover='above']::after {
    top: 0;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .workflow-step-wrapper:not(.horizontal)[workflow-drop-hover='below']::before,
  .workflow-step-wrapper:not(.horizontal)[workflow-drop-hover='below']::after {
    bottom: 0;
    right: 50%;
    transform: translate(50%, 50%);
  }

  .workflow-step-wrapper:not(.horizontal)[workflow-drop-hover='right']::before,
  .workflow-step-wrapper:not(.horizontal)[workflow-drop-hover='right']::after {
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }

  .workflow-step-wrapper:not(.horizontal)[workflow-drop-hover='left']::before,
  .workflow-step-wrapper:not(.horizontal)[workflow-drop-hover='left']::after {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .workflow-step-wrapper.horizontal[workflow-drop-hover='above']::before,
  .workflow-step-wrapper.horizontal[workflow-drop-hover='above']::after {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .workflow-step-wrapper.horizontal[workflow-drop-hover='below']::before,
  .workflow-step-wrapper.horizontal[workflow-drop-hover='below']::after {
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }

  .workflow-step-wrapper.horizontal[workflow-drop-hover='right']::before,
  .workflow-step-wrapper.horizontal[workflow-drop-hover='right']::after {
    bottom: 0;
    right: 50%;
    transform: translate(50%, 50%);
  }

  .workflow-step-wrapper.horizontal[workflow-drop-hover='left']::before,
  .workflow-step-wrapper.horizontal[workflow-drop-hover='left']::after {
    top: 0;
    right: 50%;
    transform: translate(50%, -50%);
  }

  @keyframes wiggle {
    0% {
      transform: translateX(0);
      border: 2px solid red;
    }
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(0);
    }
    75% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
      border: 2px solid red;
    }
  }

  @keyframes backgroundOpacity {
    0% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.8;
    }
  }

  .workflow-step-wrapper.connector-target {
    box-shadow: 0 0 4px 1px green;
  }
}
