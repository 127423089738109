.dnd-container {
  &.gu-empty {
    @apply tw-border-2 tw-border-dotted tw-border-error-500;
  }
  &:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .dnd-ex-moved {
    @apply tw-bg-warn-500;
  }
  .dnd-ex-over {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .dnd-handle {
    @apply tw-px-3 tw-mr-3 tw-bg-neutral-400 tw-cursor-move;
  }
}

.dnd-no-select {
  @apply tw-select-none;
}

.dnd-item {
  @apply tw-transition-opacity tw-block;

  &:not(.dnd-has-handle):not(.dnd-move-disabled),
  &.dnd-has-handle [sidkikdraghandle],
  &.dnd-has-handle [sidkikDragHandle] {
    @apply tw-cursor-move tw-cursor-grab;
  }

  .dnd-content {
    @apply tw-select-none;
  }

  &:hover {
    // border: 1px solid blue;
  }
}

.gu-mirror {
  @apply tw-fixed tw-m-0 tw-z-[9999] tw-opacity-80 tw-scale-110;
}

.gu-hide {
  @apply tw-hidden;
}

.gu-unselectable {
  @apply tw-select-none;
}
.gu-transit {
  @apply tw-opacity-20;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0.5;
}
