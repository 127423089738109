.sidkik-image-block {
  @apply tw-flex tw-items-center tw-justify-center;
  .sidkik-image {
    @apply tw-my-5 tw-prose;
    &.sidkik-image--with-border {
      img {
        @apply tw-border-gray-200 tw-border-2;
      }
    }
    &.sidkik-image--stretched {
      @apply tw-max-w-full tw-w-full lg:tw-w-10/12;
      img {
        @apply tw-w-full;
      }
    }
    &.sidkik-image--with-background {
      @apply tw-bg-gray-100 tw-p-5;
    }
    .sidkik-image-caption {
      @apply tw-text-center tw-text-sm;
    }
  }
}

.ce-delimiter {
  @apply tw-w-full tw-my-8 tw-border-t tw-border-gray-900/10 tw-h-[1px];
}

.ce-delimiter::before {
  content: '' !important;
}
.codex-editor {
  width: 100%;
  h1.ce-header {
    @apply tw-text-3xl tw-font-medium;
  }
  h2.ce-header {
    @apply tw-text-2xl tw-font-medium;
  }
  h3.ce-header {
    @apply tw-text-xl tw-font-medium;
  }
  h4.ce-header {
    @apply tw-text-lg tw-font-medium;
  }
}

// if not visible - editor reverts to narrow on initialization
sidkik-tabs {
  .codex-editor {
    // @apply prose;
  }
  .codex-editor--narrow {
    .ce-toolbar__actions {
      position: absolute;
      right: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-right: 5px;
    }
    .codex-editor__redactor {
      margin-right: 0;
    }
  }
}
.cdk-overlay-container {
  .codex-editor--narrow {
    padding-left: 50px;
    .ce-block--focused {
      margin-right: unset;
      padding-right: unset;
    }
    .ce-toolbar__actions {
      position: absolute;
      left: -10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-right: 5px;
      right: unset;
      .ce-toolbox {
        right: unset;
        .ce-popover {
          right: unset;
        }
      }
    }
    .codex-editor__redactor {
      margin-right: 0;
    }
  }
}

.editorjs-code-block {
  margin: 10px 0 10px 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  width: 100%;
  position: relative;

  .control-container {
    padding: 10px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    background-color: #f3f3f3;
    border-radius: 5px 5px 0 0;

    .editorjs-code-language-select {
      position: relative;

      & * {
        font-size: 12px;
        font-family: Arial, Helvetica, sans-serif;
      }

      select {
        display: none;
      }

      .select-selected {
        background-color: transparent;
        padding: 5px 20px 5px 10px;
        transition: background-color 0.2s;
        width: fit-content;
        border-radius: 5px;
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.5);
        }

        .arrow-icon {
          position: absolute;
          width: 10px;
          height: 10px;
          right: 5px;
          top: 6px;
          transition: transform 0.2s;
        }

        &.select-arrow-active {
          .arrow-icon {
            transform: rotateZ(180deg);
          }
        }
      }

      .select-items {
        padding: 5px;
        height: auto;
        background-color: #292929;
        width: fit-content;
        border-radius: 5px;
        margin-top: 5px;
        position: absolute;
        z-index: 5;

        .search-input {
          margin: 5px;
          padding: 5px;
          background-color: lighten($color: #292929, $amount: 20%);
          border: 0;
          outline: 0;
          border-radius: 2px;
          color: white;
        }

        .items-list {
          max-height: 300px;
          overflow-y: auto;

          div {
            color: white;
            cursor: pointer;
            padding: 5px 10px 5px 10px;
            margin: 5px 0 5px 0;
            border-radius: 5px;
            background-color: #292929;
            transition: background-color 0.2s;
          }

          .same-as-selected,
          div:hover {
            background-color: lighten(#292929, 10%);
          }
        }
      }

      .select-hide {
        display: none;
      }
    }

    .language {
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      margin: 5px;
    }

    .btn-div {
      .control-btn {
        padding: 5px 10px 5px 10px;
        border: 1px solid black;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          background-color: rgba(46, 46, 46, 0.2);
        }
      }

      .copy-btn {
        border-radius: 5px 0 0 5px;

        .copy-icon {
          width: 10px;
          height: 10px;
          display: none;
        }

        &.copy-btn-only {
          border-radius: 5px;
        }
      }

      .caption-btn {
        border-radius: 0 5px 5px 0;
        border-left: 0;
      }
    }
  }

  .content-container {
    position: relative;
    background-color: #f3f3f3;
    border-radius: 0 0 5px 5px;

    .rendered-layer {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0;
      // display: none;

      pre {
        margin: 0;
        white-space: pre-wrap;
        word-wrap: break-word;

        code {
          font-size: 14px;
          padding: 20px;
          padding-top: 5px;
          border-radius: 5px 5px 0 0;
          line-height: 2;
        }
      }
    }

    .input-layer {
      position: relative;
      z-index: 1;

      color: transparent;
      background: transparent;
      caret-color: black;
      border: 0;

      padding: 20px;
      padding-top: 5px;

      width: 100%;

      resize: none;
      overflow: hidden;
      min-height: 150px;

      outline: 0;

      &::selection {
        background-color: rgba(212, 236, 255, 0.5);
      }

      &::-moz-selection {
        background-color: rgba(212, 236, 255, 0.5);
      }
    }

    .input-layer,
    .rendered-layer,
    .rendered-layer * {
      font-size: 14px;
      font-family: monospace;
      tab-size: 2;
      line-height: 2;
    }
  }

  .caption-input {
    border: 0;
    outline: 0;
    margin-top: 5px;
    width: 100%;
    color: gray;
    background-color: transparent;
  }
}

code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}

.editorjs-modal {
  @apply tw-absolute tw-z-[10000] tw-w-screen tw-top-0 tw-left-0 tw-hidden tw-h-screen tw-place-items-center tw-backdrop-blur-sm;
  .modal-header {
    @apply tw-mx-auto tw-mb-4;
    .modal-title {
      @apply tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900;
    }
  }
  .modal-content {
    @apply tw-relative tw-w-[90%] tw-h-[90%] tw-border-2 tw-border-gray-300 tw-rounded-md tw-bg-white tw-max-h-[90%] tw-overflow-y-auto tw-py-4 tw-px-4 sm:tw-px-6 lg:tw-px-8;
    .form-section {
      @apply tw-mb-4;
      .input-wrapper {
        @apply tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm;
        .input-holder {
          @apply tw-relative tw-flex tw-flex-grow tw-items-stretch focus-within:tw-z-10;
        }
      }
    }

    .image-grid {
      @apply tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-8 sm:tw-grid-cols-3 sm:tw-gap-x-6 lg:tw-grid-cols-6 xl:tw-gap-x-8;
    }
    .image-grid__item {
      @apply tw-relative tw-cursor-pointer;
      .image-grid__wrapper {
        @apply tw-aspect-w-10 tw-aspect-h-7 tw-block tw-w-full tw-overflow-hidden tw-rounded-lg tw-bg-gray-100 focus-within:tw-ring-2 focus-within:tw-ring-indigo-500 focus-within:tw-ring-offset-2 focus-within:tw-ring-offset-gray-100;
        .image-grid__img {
          @apply tw-h-full tw-w-full tw-rounded-sm tw-block tw-pointer-events-none tw-object-cover;
        }
      }
      .image-grid__title {
        @apply tw-pointer-events-none tw-mt-2 tw-block tw-truncate tw-text-sm tw-font-medium tw-text-gray-900;
      }
      .image-grid__subtitle {
        @apply tw-pointer-events-none tw-block tw-text-sm tw-font-medium tw-text-gray-500;
      }
    }
    .empty-grid {
      @apply tw-h-full tw-grid tw-place-items-center;
    }
  }
}

.sidkik-editorjs-form-wrapper {
  @apply tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-50 tw-border tw-border-gray-100 tw-rounded-md tw-p-4 tw-gap-4;
}

.ce-link-autocomplete {
  &__icon-wrapper svg {
    display: block;
  }

  &__actions-wrapper {
    @apply tw-border-t tw-border-gray-200 tw-w-full;
  }

  &__field {
    position: relative;

    // &-input {
    //   @apply tw-w-full tw-m-2 tw-p-2 tw-rounded-md tw-border-2 tw-border-gray-200 tw-bg-gray-100 tw-outline-none tw-appearance-none tw-box-sizing-border-box tw-font-medium tw-font-normal tw-tracking-tight tw-text-gray-900;
    //   outline: none;
    //   -webkit-appearance: none;
    //   box-sizing: border-box;
    //   font-size: 14px;

    //   &::placeholder {
    //     @apply tw-text-gray-500;
    //   }
    // }

    /**
       * Loader created in DOM but hidden bu default
       */
    &::after {
      $loader-size: 14px;
      $loader-border-width: 2px;

      content: '';
      position: absolute;
      right: 16px;
      top: 50%;
      margin-top: calc($loader-size / 2 * -1);

      width: $loader-size;
      height: $loader-size;
      border: $loader-border-width solid gray;
      border-top: $loader-border-width solid transparent;
      border-radius: 50%;
      opacity: 0;
      will-change: transform, opacity;
      box-sizing: border-box;
    }

    &--loading::after {
      animation: ce-link-autocomplete__spin 1s linear infinite;
      opacity: 1;
    }
  }

  &__items {
    margin: 0 6px;
  }

  &__search-item {
    outline: none;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    padding: 7px 10px;
    margin-bottom: 6px;
    box-sizing: border-box;
    cursor: pointer;

    &:not(:last-of-type) {
    }

    &:hover,
    &--selected {
      @apply tw-bg-gray-100;
    }

    &-name {
      font-size: 14px;
      line-height: 16px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      color: #7b7e89;

      font-size: 12px;
      line-height: 16px;
      margin-top: 1px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__hidden {
    display: none;
  }

  &__link-data {
    &-wrapper {
      @apply tw-w-full tw-rounded-md tw-p-2;
    }

    &-title-wrapper {
      margin-bottom: 10px;
    }

    &-name {
      @apply tw-flex tw-items-center tw-font-medium tw-text-gray-900 tw-text-sm tw-leading-4 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis;
      &-label {
        @apply tw-flex-grow tw-font-medium tw-text-gray-700 tw-text-sm tw-leading-4 tw-mr-2 tw-truncate;
      }
      &-type {
        @apply tw-text-gray-500 tw-text-xs tw-uppercase tw-leading-4 tw-mr-4;
      }
      &-clear {
        @apply tw-text-gray-500 tw-text-xs tw-leading-4 tw-mr-2;
      }
    }

    &-description {
      color: #7b7e89;

      font-size: 12px;
      line-height: 16px;
      margin-top: 1px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-url {
      display: block;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.ce-popover {
  --border-radius: 6px;
  --width: 400px;
  --max-height: 270px;

  // Define variables based on media queries
  @media (max-width: 600px) {
    --width: 200px; // Full width on small screens
  }

  @media (min-width: 601px) and (max-width: 900px) {
    --width: 300px; // 80% width on medium screens
  }
}
